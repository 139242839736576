import React, { useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import Header from '@/components/Header';
import Footer from '@/components/Footer';

import ProductDetailBatai from '@/components/ProductDetailBatai';
import ProductDetailGaojush from '@/components/ProductDetailGaojush';
import ProductDetailHeigouqi from '@/components/ProductDetailHeigouqi';
import ProductDetailRuangutai from '@/components/ProductDetailRuangutai';
import FloatingWindow from '@/components/FloatingWindow';

import { ImageData, TitlesData } from '@/utils/interface';
import Banner from '@/components/Banner';
import banner_2 from '@/assets/banner_2.png';
import h5_banner_2 from '@/assets/H5_banner_2.jpg';
import banner_3 from '@/assets/banner_3.png';
import h5_banner_3 from '@/assets/H5_banner_3.jpg';
import banner_4 from '@/assets/banner_4.png';
import h5_banner_4 from '@/assets/H5_banner_4.jpg';
import banner_5 from '@/assets/banner_5.jpg';
import h5_banner_5 from '@/assets/H5_banner_5.jpg';
// import { useHistory } from 'react-router-dom';


class ProductDetail extends React.Component<any, IState> {
  
  constructor(props) {
    super(props);
    this.state = {
      isMobile: (window.innerWidth <= 576),
    };
    this.data = null;
  }

  componentWillReceiveProps(newProps) {
    window.location.reload();
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const searchParams = new URLSearchParams(window.location.search);
    const id = searchParams.get('id');
    this.fetchData(id);

    // const history = useHistory();
    // const unlisten = history.listen(() => {
    //   window.scrollTo(0, 0);
    // });
    // return () => {
    //   unlisten();
    // };

    window.addEventListener('resize', this.handleResize);
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    
    this.setState({ isMobile: (window.innerWidth <= 576) });
  };

  fetchData(id) {
    //id = 0 高聚生口服液 banner_5
    //id = 1 八肽饮品 banner_2
    //id = 2 软骨肽片 banner_4
    //id = 3 黑枸杞地龙蛋白片 banner_3

    const { isMobile } = this.state;

    let bannerName: string = '';
    let bannerTitle: string = '';
    let bannerSec: string = '';
    let color: string = '';
    switch(id) {
      case '0':{
        bannerName = isMobile ? h5_banner_5 : banner_5;
        bannerTitle = '超抗原™高聚生口服液';
        bannerSec = '免疫提升远离恶细胞';
        color = '#FFF';
        break;
      }
      case '1':{
        bannerName = isMobile ? h5_banner_2 : banner_2;
        bannerTitle = '超抗源™八肽饮品';
        bannerSec = '八肽合一 全方位增强';
        color = '#000';
        break;
      }
      case '2':{
        bannerName = isMobile ? h5_banner_4 : banner_4;
        bannerTitle = '超抗源™软骨肽片';
        bannerSec = '关节突发信号 需要及时警惕！';
        color = '#000';
        break;
      }
      case '3':{
        bannerName = isMobile ? h5_banner_3 : banner_3;
        bannerTitle = '超抗源™黑枸杞地龙蛋白片';
        bannerSec = '运动营养品 补充蛋白质';
        color = '#FFF';
        break;
      }
      default: 
        break;
    }

    this.data = {bannerDataList: [{id: 0, image: bannerName}], titles: {title: bannerTitle, sec: bannerSec, color: color}, id: id};
    this.forceUpdate();

    // const data: any = {bannerDataList: [{id: 0, image: bannerName}]};
    // this.setState(data);
  }

  render () {

    // const { data } = this.state;
    const data = this.data;

    const titlesData: TitlesData = data && data.titles;
    const bannerDataList: ImageData[] = data && data.bannerDataList;
    const ID = data && data.id;

    return (
      <DocumentTitle title={'产品详情'}>
        <div className="home-container">
          <Header curActive={'active'} />
          <div style={{background: '#fff', marginTop: '0', width: '100vw'}}>
            <Banner dataList={ bannerDataList } titles={ titlesData }/>
            {ID && ID==='0' && <ProductDetailGaojush />}
            {ID && ID==='1' && <ProductDetailBatai />}
            {ID && ID==='2' && <ProductDetailRuangutai />}
            {ID && ID==='3' && <ProductDetailHeigouqi />}

            {/* <FloatingWindow /> */}
          </div>
          <Footer />
        </div>
      </DocumentTitle>
    );
  }
}

export default ProductDetail;