import React, { useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import { List } from 'antd';

import { AboutItem, ImageData, TitlesData} from '@/utils/interface';
import Banner from '@/components/Banner';
import banner_6 from '@/assets/banner_6.png';
import h5_banner_6 from '@/assets/H5_banner_6.jpg';
import aboutp1 from '@/assets/pc_aboutus_pic1.png';
import aboutp2 from '@/assets/pc_aboutus_pic2.png';
import aboutp3 from '@/assets/pc_aboutus_pic3.png';
import aboutp4 from '@/assets/pc_aboutus_pic4.png';
import decoLeft from '@/assets/text_deco_left.png';
import decoRight from '@/assets/text_deco_right.png';
import '@/styles/about.less';

class About extends React.Component<any, IState> {

  constructor(props) {
    super(props);

    this.state = {
      isMobile: (window.innerWidth <= 576),
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    
    this.setState({ isMobile: (window.innerWidth <= 576) });
  };

  render () {

    const { isMobile } = this.state;
    const bannerDataList: ImageData[] = [{id: 0, image: (isMobile ? h5_banner_6 : banner_6)}];
    const titles: TitlesData = {title: '我们的使命', sec: '以人为本  健康至上', color: '#fff'};

    const dataList: AboutItem[] = [
      {id: 0, title: '关于我们', content: '在新协合，我们深知健康对于每个人和家庭的重要性，因此始终秉持“以人为本，健康至上”的理念，致力于为广大客户提供专业、全面、贴心的健康服务。作为一家新兴的健康管理企业，新协合凭借先进的科技、专业的团队和严谨的服务体系，成为您值得信赖的健康专家。\n\n我们拥有一支高素质、经验丰富的专业团队，为您提供个性化、精准的健康解决方案。通过全面的健康检查、评估和跟踪服务，我们为您及时发现潜在的健康问题，并提供专业级的预防和治疗建议。此外，我们还为您提供定制化的健康饮食、运动和生活方式指导，帮助您实现健康生活的目标。\n\n我们不断创新，追求卓越，旨在成为您和家人健康路上的守护者。让我们携手共进，为您和家人的健康保驾护航，共创美好未来！', image: [aboutp1]},
      {id: 1, title: '强大的研发背景', content: '新协合研发中心是我们企业的科技引擎，以强大的实力和创新能力，为客户提供可靠的健康解决方案。我们拥有高素质、经验丰富的专业团队和先进设备，将最新的科学成果和技术应用于健康管理领域。\n\n通过严格的科学验证和临床实践，我们致力于提供行业领先的产品和服务。我们与国内外知名科研机构和专家合作，保持前沿技术的获取，为客户提供最先进、最可靠的健康解决方案。我们始终坚守质量和客户需求，为您和家人创造一个更健康、更美好的未来！', image: [aboutp3, aboutp2]},
      {id: 2, title: '我们的团队', content: '新协合团队由专业、敬业的成员组成，以其卓越的专业素养和无私奉献的精神，为客户提供可靠的健康服务。我们始终以客户的需求为中心，通过个性化的解决方案和贴心的关怀，帮助客户实现健康目标。我们团结合作，紧密协作，以卓越的团队精神推动创新和进步。您可以信赖我们，与我们携手，共同迈向健康的未来！', image: [aboutp4]}
    ];

    return (
      <DocumentTitle title={'关于我们'}>
      <div className="home-container">
        <Header curActive={'active'}/>
        <div style={{background: '#fff', marginTop: '0', width: '100%'}}>
          <Banner dataList={ bannerDataList } titles={ titles }/>
          <List
            itemLayout="horizontal"
            dataSource={dataList}
            renderItem={(item, index) => (
              <List.Item style={{borderBottomColor:'transparent'}} className="list-item" key={item.id}>
                {isMobile ? (
                  <AboutItemCenter data={ item } key={ item.id }/>
                ): 
                (index % 2 === 0 ? <AboutItemLeft data={ item } key={ item.id }/> : <AboutItemRight data={ item } key={ item.id }/>)}
              </List.Item>
            )}
          />
        </div>
        <Footer />
      </div>
    </DocumentTitle>
    );
  }
}

const AboutItemCenter: React.FC<AboutItem>= ({ data }) => {

  return (
    <div className="abouts-content">
      <div className='title-container'>
        <img className='image' src={decoLeft} alt="icon" />
        <p className='title'>{data.title}</p>
        <img className='image' src={decoRight} alt="icon" />
      </div>
      <div className="layout-container">
        <div className="content-row">
          <div className="right-content">
            <div className='image-wrapper'>
              {data.image.length > 0 && data.image.map((item, index)=>(
                <div key={index} className="image-item">
                  <img className='img' src={item} alt="banner"/>
                </div>
              ))}
            </div>
            {/* <img src={aboutp1} alt="photoImage" /> */}
          </div>
          <div className="left-content">
            <div className="left-content-inner">
              <p className='info'> {data.content} </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const AboutItemRight: React.FC<AboutItem>= ({ data }) => {

  return (
    <div className="abouts-content">
      <div className='title-container'>
        <img className='image' src={decoLeft} alt="icon" />
        <p className='title'>{data.title}</p>
        <img className='image' src={decoRight} alt="icon" />
      </div>
      <div className="layout-container">
        <div className="content-row">
          <div className="left-content">
            <div className="left-content-inner">
              {/* <p className='title'>关于我们</p> */}
              <p className='info'> {data.content} </p>
            </div>
          </div>
          <div className="right-content">
            <div className='image-wrapper'>
              {data.image.length > 0 && data.image.map((item, index)=>(
                <div key={index} className="image-item">
                  <img src={item} alt="banner"/>
                </div>
              ))}
            </div>
            {/* <img src={aboutp1} alt="photoImage" /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

const AboutItemLeft: React.FC<AboutItem>= ({ data }) => {

  console.log('**********AboutItemLeft:', data.image[0]);
  console.log('**********AboutItemLeft 1:', aboutp1);

  return (
    <div className="abouts-content">
      <div className='title-container'>
        <img className='image' src={decoLeft} alt="icon" />
        <p className='title'>{data.title}</p>
        <img className='image' src={decoRight} alt="icon" />
      </div>
      <div className="layout-container">
        <div className="content-row">
          <div className="right-content-left">
            <img src={(data.image.length > 0 ? data.image[0] : '')} alt="photoImage" />
          </div>
          <div className="left-content-left">
            <div className="left-content-inner">
              <p className='info'> {data.content} </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;