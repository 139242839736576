import React from 'react';
import { useState, useEffect } from 'react';
import { Row, Col, Card, Divider , Descriptions, List} from 'antd';

import '@/styles/productdetailgaojush.less';
import decoLeft from '@/assets/text_deco_left.png';
import decoRight from '@/assets/text_deco_right.png';
import itemOne1 from '@/assets/pc_product_ruangutai_pic1.png';
import itemOne2 from '@/assets/pc_product_ruangutai_pic2.png';
import itemOne3 from '@/assets/pc_product_ruangutai_pic3.png';
import itemOne4 from '@/assets/pc_product_ruangutai_pic4.png';
import itemGJ from '@/assets/pc_home_product_1_gaojusheng.png';
import itemTwo1 from '@/assets/pc_product_ruangutai_material_1.png';
import itemTwo2 from '@/assets/pc_product_ruangutai_material_2.png';
import itemTwo3 from '@/assets/pc_product_ruangutai_material_3.png';
import itemTwo4 from '@/assets/pc_product_ruangutai_material_4.png';
import itemTwo5 from '@/assets/pc_product_ruangutai_material_5.png';
import itemTwo6 from '@/assets/pc_product_ruangutai_material_6.png';
import itemTwo from '@/assets/pc_product_ruangutai_renzheng1.png';
import itemTwoH5 from '@/assets/pc_product_ruangutai_renzheng1_h5.png';
import itemThree1 from '@/assets/pc_product_ruangutai_pic5.png';
import itemThree2 from '@/assets/pc_product_ruangutai_pic6.png';
import itemThree3 from '@/assets/pc_product_ruangutai_pic7.png';

interface ItemOne1 {
  id: number;
  title: string;
  text: string;
  image: string;
};

interface ItemOne2 {
  id: number;
  title: string;
  text: string;
  image: string;
};

interface ItemThree {
  id: number;
  title: string;
  text: string;
  image: string;
};

interface ItemFive {
  id: number;
  title: string;
  text: string;
};

interface ProductGItemOneProps {
  data: {
    dataOne: ItemOne1[];
    dataTwo: ItemOne2[];
  }
}

const ProductDetailGaojush = () => {

  const itemOneData1: ItemOne1[] = [
    {id: 0, title: '恶心 呕吐', text: '放化疗对正常增殖旺盛的肿瘤细胞，如消化道肿瘤细胞杀伤较强，引起胃肠反应，导致食欲差，恶心呕吐', image: itemOne1},
    {id: 1, title: '白细胞低', text: '放化疗在杀伤肿瘤细胞的同时，对正常免疫细胞，如白细胞，淋巴细胞，巨噬细胞一并杀死，导致免疫力下降', image: itemOne2},
    {id: 2, title: '体虚头昏', text: '由于正常营养被肿瘤细胞抢夺，导致营养不良，加上化疗残留物质对神经系统的干扰和损伤，容易造成大脑及中枢神经感知信号异常', image: itemOne3},
    {id: 3, title: '复发风险', text: '随着年龄的增长，免疫功能的下降，再加上免疫系统受到放化疗的损伤，而导致免疫细胞受到束缚，有利于肿瘤细胞发生免疫逃逸，增加复发的风险', image: itemOne4},
  ];

  const itemOneData2: ItemOne2[] = [
    {id: 0, title: '增强免疫力', text: '对提高免疫应答速度有帮助', image: itemTwo2},
    {id: 1, title: '利于代谢', text: '对促进细胞新陈代谢有帮助', image: itemTwo3},
    {id: 2, title: '减少毒副作用', text: '可以搭配放化疗使用，减少放化疗带来的副作用', image: itemTwo4},
    {id: 3, title: '适用免疫力低下人群', text: '婴幼儿、孕妇、乳母、有内出血者慎用，器官移植者禁用', image: itemTwo5},
    {id: 4, title: '提高身体机能', text: '对延缓机体衰老有帮助', image: itemTwo6},
    {id: 5, title: '促进康复', text: '对骨子疏松恢复有帮助', image: itemTwo1},
  ];

  const itemThreeData: ItemThree[] = [
    {id: 0, title: '企业简介', text: '        协合生物集团成立于1988年，历经30多年的不懈努力，建成了以太空生物技术、协合超抗原技术和基因工程技术为基础的科研平台，并顺利完成了多项国家火炬计划项目，其中专利技术协合超抗原连续入选十一五、十二五重点国家项目。\
    \n         本公司是国家科技部火炬中心认定的重点高新技术企业，多年来一直致力于生物医药和健康品的研究，是世界上第一个将超抗原产品推向临床的企业，其拥有完全自主知识产权的协合超抗原系列产品在癌症、艾滋病、骨病等疑难病症的预防和治疗上已经取得了显善的成绩。\
    \n         协合生物秉承“协合康健达天下，生物科技惠万家”的企业理念，以提高人类健康水平为崇高使命，通过发挥科技与质管优势，强化市场服务，矢志将公司打造成为国际生物医药健康全产业链的标杆企业。', image: itemThree1, isSubInfo: false},
    {id: 1, title: '神七问天', text: '        本报讯(记者李海英)神舟七号载人飞船的发射成功，更让沈阳协合集团的全体员工激动万分。因为神舟七号搭载着他们的生物技术成果一超级抗原四个生物菌种，这也是此次神舟七号搭载的唯一生物菌种据介绍，这四个超级抗原菌种，主要是防治癌症、艾滋病、免疫系统疾病、心脑血管疾病的菌种，经中国科学院、中国工程院多位院士联合推荐，经中国微生物菌种中心和中国空间科学学会的层层筛选，专家们严格评审而最终确定搭载的。\
    \n        此次飞天是利用太空的特殊环境，如空间宇宙射线、微重力、高真空、微磁场等因素，增强菌种的活性和表达量，这对于大幅度提高超级抗原的生物利用度，增加超级抗原的表达量，将产生重大影响。据了解，这四个菌种经过空间诱变育种后，中国科学院、中国工程院将组织生物、医学、微生物专家，对菌种进行研究，培育新品种。', image: itemThree2, isSubInfo: true}
  ];

  const itemFiveData: ItemFive[] = [
    {id: 0, title: '食用方法:', text: '宜空腹口服食用。食用时要在口腔里含服3-5分钟后慢慢咽下， 让口腔黏膜充分吸收。食用后半小时内不宜进食喝水，以免刺激胃酸分泌削弱效果。半小时后再开始大量喝水来补充体液，加强吸收效果。'},
    {id: 1, title: '注意事项:', text: '1、适用人群范围需按照说明书上的要求进行； \n2、有好转反应出现时根据实际情况酌情减少食用量或者坚持食用，让身体快速适应； \n3、放化疗期间，可以搭配高聚生口服液和其他治疗用药一起食用，搭配起来效果更好。'}
  ];

  const itemSixData: ItemFive[] = [
    {id: 0, title: '好转反应解释:', text: '医学界经过研究和试验证明，任何功能性保健食品使用后都会有或多或少的不良反应，中医称为”瞑眩反应”，它并不是副作用，这是对人体各器官进行有效调节所产生的一种现象，在保健及调理过程中。必然会有各种各样的调整反应，《尚书》称”药不眩，厥疾勿摩”，其意为服药后若人体没有明显的反应，则疾病难以被治愈，若药后有较明显反应者效果较好。'},
    {id: 1, title: '各类细胞更新周期一览:', text: '1、人体胃肠黏膜细胞7天左右更新一次 \n2、皮肤细胞28天左右更新一次 \n3、肝脏细胞180天左右更新一次 \n4、红血球细胞120天左右更新一次 \n5、在一年左右的时间，身体98%的细胞都会被重新更新一次。而骨细胞更新需要7年'}
  ];

  return (
    <div className='pg-content'>
      <PGTitle title='科普知识：什么是超级抗原'/>
      <ProductGItemOne data={{itemOneData1, itemOneData2}}/>

      <PGTitle title='安全蓝帽 国家批文'/>
      <ProductGItemTwo/>

      <PGTitle title='中国创造 造福全球'/>
      <ProductGItemThree data={itemThreeData}/>

      <PGTitle title='回报社会 奉献爱心'/>
      <ProductGItemFour/>

      <PGTitle title='如何服用超抗源产品'/>
      <ProductGItemFive data={itemFiveData}/>

      <PGTitle title='科普知识：好转反应'/>
      <ProductGItemSix data={itemSixData}/>
    </div>
  );
}

export default ProductDetailGaojush;

const PGTitle: React.FC<string>= ({ title }) => {

  return (
    <div className='pg-title-container'>
      <img className='pg-image' src={decoLeft} alt="icon" />
      <p className='pg-title'>{title}</p>
      <img className='pg-image' src={decoRight} alt="icon" />
    </div>
  );
};

const ProductGItemOne: React.FC<ProductGItemOneProps>= ( {data} ) => {
  const{itemOneData1, itemOneData2} = data;

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
  React.useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth <= 576);
      };
  
      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  if(isMobile) {
    return (
      <div className='pg-one-container'>
        <div className='pg-one-card-container'>
          <Row gutter={16}>
            <Col span={24}>
              <div className="pg-one-card-info-container">
                <div className='pg-one-card-info-center'>
                  <Card className="pg-one-card-info-card">
                    <p className='pg-one-card-title'>为什么是“超级抗原”</p>
                    <p className='pg-one-card-info'>{`超级抗原是普通抗原平均2000-50000倍的速度激活人体的免疫细胞\n免疫低下是万病之源，免疫系统的提升，有利于预防和治疗疾病，提升免疫是防治的关键，快速的康复是与时间赛跑`}</p>
                    <div className='pg-one-tag-container'>
                      <Row gutter={[16, 32]} className='pg-one-tag-row'>
                        <Col span={12}>
                          <div className='pg-one-tag-box'>
                            <p className='pg-one-tag-box-text'>科技转换</p>
                          </div>
                        </Col>
                        <Col span={12}>
                          <div className='pg-one-tag-box'>
                            <p className='pg-one-tag-box-text'>严谨配方</p>
                          </div>
                        </Col>
                      </Row>
                      <Row gutter={[16, 32]} className='pg-one-tag-row'>
                        <Col span={12}>
                          <div className='pg-one-tag-box'>
                            <p className='pg-one-tag-box-text'>严格检测</p>
                          </div>
                        </Col>
                        <Col span={12} className="pg-one-tag-col">
                          <div className='pg-one-tag-box'>
                            <p className='pg-one-tag-box-text'>成熟工艺</p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </div>
              </div>
            </Col>
            <Col span={24}>
              <Card className="pg-one-card-product-image">
                <img src={ itemGJ } alt="gaojushen" />
              </Card>
            </Col>
          </Row>
        </div>
        <div>
          <Row gutter={16} className="pg-one-container-b">
          {itemOneData1.map((item) => (
            <Col span={24} key={item.id}>
              <div className="pg-one-card">
                <div
                  className="pg-one-image"
                  style={{ backgroundImage: `url(${item.image})` }}
                >
                  <div className="pg-one-text">{item.title}</div>
                </div>
                <div className="pg-one-info">{item.text}</div>
              </div>
            </Col>
          ))}
          </Row>
        </div>
        <div className='pg-one-circle-container'>
          <div className="circle-container">
            <Row justify="center" align="middle" className="circle-row">
              <Col span={24}>
                <div className="circle">
                  <div class="container">
                    <img src={itemGJ} alt="gaojushrn" className='content-center-img'/>
                    <p className='content-center-p'>六大特点</p>
                  </div>
                  <div>
                    {itemOneData2.map((item, index) => (
                      <div class="circle-image" key={index}>
                        <div class="circle-image-container" style={{backgroundImage: `url(${item.image})`}}>
                          {/* <img src={item.image} alt="gaojushen" /> */}
                          <div class="circle-image-text-overlay">
                            <p className='circle-image-container-title'>{item.title}</p>
                            {/* <p className='circle-image-container-text'>{item.text}</p> */}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
  else {
    return (
      <div className='pg-one-container'>
        <div className='pg-one-card-container'>
          <Row gutter={16}>
            <Col span={12}>
              <Card className="pg-one-card-product-image">
                <img src={ itemGJ } alt="gaojushen" />
              </Card>
            </Col>
            <Col span={12}>
              <div className="pg-one-card-info-container">
                <div className='pg-one-card-info-center'>
                  <Card className="pg-one-card-info-card">
                    <p className='pg-one-card-title'>为什么是“超级抗原”</p>
                    <p className='pg-one-card-info'>{`超级抗原是普通抗原平均2000-50000倍的速度激活人体的免疫细胞\n免疫低下是万病之源，免疫系统的提升，有利于预防和治疗疾病，提升免疫是防治的关键，快速的康复是与时间赛跑`}</p>
                    <div className='pg-one-tag-container'>
                      <Row gutter={[16, 32]} className='pg-one-tag-row'>
                        <Col span={12}>
                          <div className='pg-one-tag-box'>
                            <p className='pg-one-tag-box-text'>科技转换</p>
                          </div>
                        </Col>
                        <Col span={12}>
                          <div className='pg-one-tag-box'>
                            <p className='pg-one-tag-box-text'>严谨配方</p>
                          </div>
                        </Col>
                      </Row>
                      <Row gutter={[16, 32]} className='pg-one-tag-row'>
                        <Col span={12}>
                          <div className='pg-one-tag-box'>
                            <p className='pg-one-tag-box-text'>严格检测</p>
                          </div>
                        </Col>
                        <Col span={12} className="pg-one-tag-col">
                          <div className='pg-one-tag-box'>
                            <p className='pg-one-tag-box-text'>成熟工艺</p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div>
          <Row gutter={16} className="pg-one-container-b">
          {itemOneData1.map((item) => (
            <Col span={12} key={item.id}>
              <div className="pg-one-card">
                <div
                  className="pg-one-image"
                  style={{ backgroundImage: `url(${item.image})` }}
                >
                  <div className="pg-one-text">{item.title}</div>
                </div>
                <div className="pg-one-info">{item.text}</div>
              </div>
            </Col>
          ))}
          </Row>
        </div>
        <div className='pg-one-circle-container'>
          <div className="circle-container">
            <Row justify="center" align="middle" className="circle-row">
              <Col span={24}>
                <div className="circle">
                  <div class="container">
                    <img src={itemGJ} alt="gaojushrn" className='content-center-img'/>
                    <p className='content-center-p'>六大特点</p>
                  </div>
                  <div>
                    {itemOneData2.map((item, index) => (
                      <div class="circle-image" key={index}>
                        <div class="circle-image-container" style={{backgroundImage: `url(${item.image})`}}>
                          {/* <img src={item.image} alt="gaojushen" /> */}
                          <div class="circle-image-text-overlay">
                            <p className='circle-image-container-title'>{item.title}</p>
                            {/* <p className='circle-image-container-text'>{item.text}</p> */}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
};

const ProductGItemTwo: React.FC = () => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
  React.useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth <= 576);
      };
  
      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  return (
    <div className="pg-two-container">
      <div className='pg-two-info-container'>
        <p className='pg-two-info'>{`蓝帽子标志，是保健食品标志，由国家食药监局审批认证。\n在保健食品领域蓝帽就是通行证，但保健食品不能替代药品。`}</p>
      </div>
      <div className='pg-two-child-container'>
        <img className='pg-two-child-image' src={ isMobile? itemTwoH5 : itemTwo } alt='batai'></img>
      </div>
    </div>
  );
}

const ProductGItemThree: React.FC<ItemThree> = ({data}) => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
  React.useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth <= 576);
      };
  
      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  if(isMobile) {
    return (
      <div className="pg-three-container">
        {data.map((item, index) => {
          return(<div key={index}>
            {index%2 === 0 ? (
              <div className='pg-three-right-container'>
                <Row gutter={16} style={{width: '100%', height: '100%'}}>
                  <Col span={24}>
                    <div className="pg-three-right-content">
                      <img src={item.image} alt="gaojushen" style={{marginTop: '20px', justifyContent: 'center'}}/>
                    </div>
                  </Col>
                  <Col span={24}>
                    <div className="pg-three-left-content">
                      <p className="pg-three-top-text">{item.title}</p>
                      <p className="pg-three-bottom-text">{item.text}</p>
                    </div>
                  </Col>
                </Row>
              </div>
              ) : (
                <div className='pg-three-left-container'>
                  <Row gutter={16} style={{width: '100%', height: '100%'}}>
                    <Col span={24}>
                      <div className="pg-three-left-right-content">
                        <img src={item.image} alt="gaojushen" style={{marginTop: '20px', justifyContent: 'center'}}/>
                        {item.isSubInfo && <span>{'中国工程院刘昌孝院士为协合集团超级抗原太空育种 \n题字祝贺“超级抗原，神七作证'}</span>}
                      </div>
                    </Col>
                    <Col span={24}>
                      <div className="pg-three-left-content">
                        <p className="pg-three-top-text">{item.title}</p>
                        <p className="pg-three-bottom-text">{item.text}</p>
                        {item.isSubInfo && <span>{'(摘自《沈阳日报》2008年9月27日)'}</span>}
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
          </div>)
        })}
      </div>
    );
  }
  else {
    return (
      <div className="pg-three-container">
        {data.map((item, index) => {
          return(<div key={index}>
            {index%2 === 0 ? (
              <div className='pg-three-right-container'>
                <Row gutter={16} style={{width: '100%', height: '100%'}}>
                  <Col span={13}>
                    <div className="pg-three-left-content">
                      <p className="pg-three-top-text">{item.title}</p>
                      <p className="pg-three-bottom-text">{item.text}</p>
                    </div>
                  </Col>
                  <Col span={11}>
                    <div className="pg-three-right-content">
                      <img src={item.image} alt="gaojushen" />
                    </div>
                  </Col>
                </Row>
              </div>
              ) : (
                <div className='pg-three-left-container'>
                  <Row gutter={16} style={{width: '100%', height: '100%'}}>
                    <Col span={11}>
                      <div className="pg-three-left-right-content">
                        <img src={item.image} alt="gaojushen" />
                        {item.isSubInfo && <span>{'中国工程院刘昌孝院士为协合集团超级抗原太空育种 \n题字祝贺“超级抗原，神七作证'}</span>}
                      </div>
                    </Col>
                    <Col span={13}>
                      <div className="pg-three-left-content">
                        <p className="pg-three-top-text">{item.title}</p>
                        <p className="pg-three-bottom-text">{item.text}</p>
                        {item.isSubInfo && <span>{'(摘自《沈阳日报》2008年9月27日)'}</span>}
                      </div>
                    </Col>
                  </Row>
                </div>
              )}
          </div>)
        })}
      </div>
    );
  }
}

const ProductGItemFour: React.FC = () => {

  return (
    <div className='pg-four-container'>
      <img src={itemThree3} alt="four" />
    </div>
  );
}

const ProductGItemFive: React.FC<ItemFive> = ({ data }) => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
  React.useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth <= 576);
      };
  
      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  return (
    <div className='pg-five-container'>
      <Row gutter={[0]} style={{width: '100%', height: '100%'}}>
        {data.map((item, index) => (
          isMobile ? (
            <Col span={24}>
              <div className="pg-five-content">
                <p className="pg-five-content-title">{item.title}</p>
                <p className="pg-five-content-text">{item.text}</p>
              </div>
            </Col>
          ):(
            <Col span={12}>
              <div className="pg-five-content" style={{marginRight: index % 2 === 0 ? '10px': '0', marginLeft: index % 2 ===0 ? '0': '10px'}}>
                <p className="pg-five-content-title">{item.title}</p>
                <p className="pg-five-content-text">{item.text}</p>
              </div>
            </Col>
          )
        ))}
      </Row>
    </div>
  );
}

const ProductGItemSix: React.FC<ItemFive> = ({ data }) => {

  return (
    <div>
      <ProductGItemFive data = {data}/>
      <div style={{height: '100px'}}></div>
    </div>
  );
}