import React from 'react';
import { useState, useEffect } from 'react';
import '@/styles/homeabout.less';

import map from '@/assets/pc_home_aboutus_map.png';
import photo from '@/assets/pc_home_aboutus_photo.png'

const HomeAbout = () => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
  useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth <= 576);
      };
  
      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  const containerStyle = {
    backgroundImage: `url(${map})`,
  };
  
  return (
    <div className="layout-container" style={containerStyle}>
      {isMobile ? (
        <div className="content-row">
          <div className="right-content">
            <img src={photo} alt="photoImage" />
          </div>
          <div className="left-content">
            <div className="left-content-inner">
              <p className='title'>关于我们</p>
              <p className='info'>在新协合，我们深知健康对于每个人和家庭的重要性，因此始终秉持“以人为本，健康至上”的理念，致力于为广大客户提供专业、全面、贴心的健康服务。作为一家新兴的健康管理企业，新协合凭借先进的科技、专业的团队和严谨的服务体系，成为您值得信赖的健康专家。 我们拥有一支高素质、经验丰富的专业团队，为您提供个性化、精准的健康解决方案。通过全面的健康检查、评估和跟踪服务，我们为您及时发现潜在的健康问题，并提供专业级的预防和治疗建议。此外，我们还为您提供定制化的健康饮食、运动和生活方式指导，帮助您实现健康生活的目标。 我们不断创新，追求卓越，旨在成为您和家人健康路上的守护者。让我们携手共进，为您和家人的健康保驾护航，共创美好未来！</p>
            </div>
          </div>
        </div>
        ) : (
        <div className="content-row">
          <div className="left-content">
            <div className="left-content-inner">
              <p className='title'>关于我们</p>
              <p className='info'>在新协合，我们深知健康对于每个人和家庭的重要性，因此始终秉持“以人为本，健康至上”的理念，致力于为广大客户提供专业、全面、贴心的健康服务。作为一家新兴的健康管理企业，新协合凭借先进的科技、专业的团队和严谨的服务体系，成为您值得信赖的健康专家。 我们拥有一支高素质、经验丰富的专业团队，为您提供个性化、精准的健康解决方案。通过全面的健康检查、评估和跟踪服务，我们为您及时发现潜在的健康问题，并提供专业级的预防和治疗建议。此外，我们还为您提供定制化的健康饮食、运动和生活方式指导，帮助您实现健康生活的目标。 我们不断创新，追求卓越，旨在成为您和家人健康路上的守护者。让我们携手共进，为您和家人的健康保驾护航，共创美好未来！</p>
            </div>
          </div>
          <div className="right-content">
            <img src={photo} alt="photoImage" />
          </div>
        </div>
      )}
    </div>
  );
}

export default HomeAbout;