import React, { useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import '@/styles/home.less';

import Banner from '@/components/Banner';
import SeriesProduct from '@/components/SeriesProduct';
import HomeProduct from '@/components/HomeProduct'
import HomeAbout from '@/components/HomeAbout'

import banner_1 from '@/assets/banner_1.jpg';
import h5_banner_1 from '@/assets/H5_banner_1.jpg';

class ProductList extends React.Component<any, IState> {

  constructor(props) {
    super(props);

    this.state = {
      isMobile: (window.innerWidth <= 576),
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    
    this.setState({ isMobile: (window.innerWidth <= 576) });
  };

  render () {
    const { isMobile } = this.state;
    const bannerDataList: ImageData[] = [{id: 0, image: isMobile ? h5_banner_1 : banner_1}];

    return (
      <DocumentTitle title={'产品列表'}>
      <div className="home-container">
        <Header curActive={'active'} />
        <div style={{background: '#fff', marginTop: '0', width: '100%'}}>
          
          <Banner dataList={ bannerDataList }/>
          <SeriesProduct/>
          <HomeProduct/>
          <HomeAbout/>

        </div>
        <Footer />
      </div>
    </DocumentTitle>
    );
  }
}

export default ProductList;