import React from 'react';
import { useState, useEffect } from 'react';
import { Row, Col, Card, Divider , Descriptions, List} from 'antd';

import '@/styles/productdetailheigouqi.less';
import decoLeft from '@/assets/text_deco_left.png';
import decoRight from '@/assets/text_deco_right.png';
import itemOne1 from '@/assets/pc_product_heigouqi_pic1.png';
import itemOne2 from '@/assets/pc_product_heigouqi_pic2.png';
import itemOne3 from '@/assets/pc_product_heigouqi_pic3.png';
import itemOne4 from '@/assets/pc_product_heigouqi_pic4.png';
import phImage from '@/assets/pc_home_product_3_hegouqi.png';
import itemThree1 from '@/assets/pc_product_heigouqi_material_1.png';
import itemThree2 from '@/assets/pc_product_heigouqi_material_2.png';
import itemThree3 from '@/assets/pc_product_heigouqi_material_3.png';
import itemThree4 from '@/assets/pc_product_heigouqi_material_4.png';
import itemThree5 from '@/assets/pc_product_heigouqi_material_5.png';
import itemThree6 from '@/assets/pc_product_heigouqi_material_6.png';
import itemThree7 from '@/assets/pc_product_heigouqi_material_7.png';
import itemThree8 from '@/assets/pc_product_heigouqi_material_8.png';


interface ItemOne {
  id: number;
  title: string;
  desc: string;
  image: string;
};

interface ItemThree {
  id: number;
  title: string;
  desc: string;
  image: string;
};

interface ItemFive {
  id: number;
  title: string;
  text: string;
};

const ProductDetailHeigouqi = () => {

  const itemOneData: ItemOne[] = [
    {id: 0, title: '血糖不稳', image: itemOne1},
    {id: 1, title: '久坐不动', image: itemOne2},
    {id: 3, title: '应酬饮酒', image: itemOne3},
    {id: 4, title: '饮食结构', image: itemOne4}
  ];

  const itemThreeData: ItemThree[] = [
    {id: 0, title: '地龙蛋白肽', desc: '地龙蛋白肽具有活血化瘀、加速代谢、缓解咳嗽等作用，平时可以适当服用。', image: itemThree1},
    {id: 1, title: '黑枸杞粉', desc: '黑枸杞粉具有：1、抗氧化；2、调节免疫系统；3、改善睡眠；4、保护肝脏；5、调节血脂和血糖；6、促进生长发育；7、抵抗辐射；8、滋阴补肾。', image: itemThree2},
    {id: 2, title: '苦瓜肽', desc: '苦瓜肽是一种从苦瓜里面提取出来的小分子肽，具有1、对抗病毒；2、调节血糖；3、提高免疫力；4、改善肌肤。', image: itemThree3},
    {id: 3, title: '小麦肽', desc: '小麦肽是一种来源于小麦蛋白的植物性生物活性肽，含有人体必需的15种氨基酸，小麦肽具有水溶性好、易吸收、生活活性强等特点。', image: itemThree4},
    {id: 4, title: '大豆分离蛋白', desc: '大豆分离蛋白中蛋白质含量在90%以上，氨基酸种类有近20种,并含有人体必需氨基酸。', image: itemThree5},
    {id: 5, title: '乳清蛋白', desc: '乳清蛋白具有：1、提高人体修复能力； 2、提高人体免疫系统；3、提高人体所需的氨基酸；4、维持人体电解质平衡； 5、提供人体所需的蛋白酶。', image: itemThree6},
    {id: 6, title: '马齿苋', desc: '马齿苋含有的核黄素、抗坏血酸、去甲肾上腺素等，能够促进我们的胰腺分泌胰岛素，起到了降低和稳定血糖的作用，对防治糖尿病有一定的积极作用。', image: itemThree7},
    {id: 7, title: '铬', desc: '人体缺铬早期并没有明显的征兆，机体内贮存的铬会释放出来，储备铬耗尽之后，仍需一段时间才会表现缺乏症状，因为机体会分泌足够的额外胰岛素来补偿因缺铬而引起胰岛素效能的降低。胰岛素分泌增多是临界缺铬的主要标志。', image: itemThree8},
  ];

  const itemFourData: ItemOne[] = [
    {id: 0, title: '优质蛋白质补充', desc: '采用三种不同来源（动物、植物及微生物）的优质蛋白，即大豆分离蛋白、乳清大白、酵母蛋白，科学配比，能够提高蛋白质的消化吸收，并实现蛋白质的互补作用。', image: itemOne1},
    {id: 1, title: '肽类', desc: '采用苦瓜肽、小麦肽、地龙蛋白肽，在补充蛋白质、肽类营养需求的同时，又兼具调节血糖、血脂及胆固醇的功能。', image: itemOne2},
    {id: 3, title: '新食品原料类', desc: 'β-羟基-β-甲基丁酸钙(HMB-Ca)：可以提高肌肉细胞内的蛋白质合成速率，增强人体固氮能力，维持体内蛋白质水平，防止肌肉蛋白质流失。酵母β-葡聚糖：提升机体免疫力，调节血脂、胆固醇、血糖，清除游离基抗氧化，增强体质。', image: itemOne3},
    {id: 4, title: '药食同源类', desc: '玉米须、黑枸杞、马齿苋，当中含有多样大量的矿物质及微量营养元素，同时兼具对血脂、胆固醇、血糖有一定的调节作用。', image: itemOne4},
    {id: 5, title: '微量元素类', desc: '三价铬：胰岛素起作用要消耗铬，适时的补充铬，则可以改善血糖表现、脂质代谢及降低体脂肪。', image: itemOne4}
  ];

  const itemFiveData: ItemFive[] = [
    {id: 0, title: '产品名称：', text: '超抗源™黑枸杞地龙蛋白片特殊膳食'},
    {id: 1, title: '产品类型：', text: '运动营养食品.补充蛋白质类'},
    {id: 2, title: '配  料 表：', text: '山梨糖醇、麦芽糊精、大豆分离蛋白、乳清蛋白、酵母蛋白、玉米须粉、苦瓜肽、黑枸杞粉、β—羥基—β—甲基丁酸钙、马齿苋粉、小麦肽、地龙蛋白肽、硬脂酸镁、酵母β—葡聚糖、三氯化铬稀释品（六水三氯化铬、麦芽精糊、葡萄糖酸钙）'},
    {id: 3, title: '产品规格：', text: '1.0g/片*60*2瓶'},
    {id: 4, title: '保  质  期：', text: '24个月'},
    {id: 5, title: '执行标准：', text: 'GB 24154'},
    {id: 7, title: '食品生产许可证编号：', text: 'SC11337100100862'},
    {id: 8, title: '不适宜人群：', text: '婴幼儿、孕产妇、哺乳期妇女、少年儿童、儿童及过敏体质者不宜食用'},
    {id: 9, title: '贮藏方法：', text: '避光，阴凉干燥处保存'},
    {id: 10, title: '温馨提示：', text: '本品不能代替药物'}
  ];

  return (
    <div className='ph-content'>

      <PHTitle title='你有以下烦恼吗'/>
      <ProductHItemOne data={itemOneData}/>

      <PHTitle title='针对性配方解决你的烦恼'/>
      <ProductHItemTwo data={itemOneData}/>

      <PHTitle title='好产品源自好原料'/>
      <ProductHItemThree data={itemThreeData}/>

      <PHTitle title='产品设计'/>
      <ProductHItemFour data={itemFourData}/>

      <PHTitle title='产品信息'/>
      <ProductBItemFive data={itemFiveData}/>

    </div>
  );
}

export default ProductDetailHeigouqi;

const PHTitle: React.FC<string>= ({ title }) => {

  return (
    <div className='ph-title-container'>
      <img className='ph-image' src={decoLeft} alt="icon" />
      <p className='ph-title'>{title}</p>
      <img className='ph-image' src={decoRight} alt="icon" />
    </div>
  );
};

const ProductHItemOne: React.FC<ItemOne>= ({ data }) => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
  React.useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth <= 576);
      };
  
      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  if(isMobile) {
    return (
      <div className='ph-grid-item-container'>
        <Row gutter={16}>
        {data.map((item) => (
          <Col span={12} key={item.id}>
            <div className="ph-grid-item">
              <div className="ph-background-image" style={{backgroundImage: `url(${item.image})`}}></div>
              <div className="ph-content">
                <div className="ph-title">{item.title}</div>
              </div>
            </div>
          </Col>
        ))}
        </Row>
      </div>
    );
  }

  return (
    <div className='ph-grid-item-container'>
      <Row gutter={16}>
      {data.map((item) => (
        <Col span={6} key={item.id}>
          <div className="ph-grid-item">
            <div className="ph-background-image" style={{backgroundImage: `url(${item.image})`}}></div>
            <div className="ph-content">
              <div className="ph-title">{item.title}</div>
            </div>
          </div>
        </Col>
      ))}
      </Row>
    </div>
  );
}; 

const ProductHItemTwo: React.FC<ItemOne>= ({ data }) => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
  React.useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth <= 576);
      };
  
      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  if(isMobile) {
    return (
      <div className='ph-one-card-container'>
        <Row gutter={16}>
          <Col span={24}>
            <Card className="ph-one-card-product-image">
              <img src={ phImage } alt="heigouqi" />
            </Card>
          </Col>
          <Col span={24}>
            <div className="ph-one-card-info-container">
              <div className='ph-one-card-info-center'>
                <Card className="ph-one-card-info-card">
                  <p className='ph-one-card-title'>超抗源™黑枸杞地龙蛋白片特殊膳食</p>
                  <p className='ph-one-card-info'>{`大豆分离蛋白、乳清蛋白、酵母蛋白、玉米须粉、苦瓜肽、黑枸杞粉、β—羥基—β—甲基丁酸钙、马齿苋粉、小麦肽、地龙蛋白肽、硬脂酸镁、酵母β—葡聚糖、三氯化铬稀释品......`}</p>
                </Card>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <div className='ph-one-card-container'>
      <Row gutter={16}>
        <Col span={12}>
          <Card className="ph-one-card-product-image">
            <img src={ phImage } alt="heigouqi" />
          </Card>
        </Col>
        <Col span={12}>
          <div className="ph-one-card-info-container">
            <div className='ph-one-card-info-center'>
              <Card className="ph-one-card-info-card">
                <p className='ph-one-card-title'>超抗源™黑枸杞地龙蛋白片特殊膳食</p>
                <p className='ph-one-card-info'>{`大豆分离蛋白、乳清蛋白、酵母蛋白、玉米须粉、苦瓜肽、黑枸杞粉、β—羥基—β—甲基丁酸钙、马齿苋粉、小麦肽、地龙蛋白肽、硬脂酸镁、酵母β—葡聚糖、三氯化铬稀释品......`}</p>
              </Card>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

const ProductHItemThree: React.FC<ItemOne>= ({ data }) => {

  return (
    <div className='ph-two-card-container'>
      {data.map((item, index) => {
        return (
          <div key={index}>
            {index % 2 === 0 ? (
              <div className='ph-two-card-left'>
                <img className='ph-two-child-left-image' src={ item.image } alt='heigouqi'></img>
                <div className='ph-two-child-content'>
                  <Row className="container">
                    <Col span={8} className="image">
                      {/* <div className='image-container'>
                        <img src={item.image} alt="icon"  className='image-content'/>
                      </div> */}
                    </Col>
                    <Col span={16} className="content">
                      <div className="text-wrapper">
                        <p className='text-wrapper-title'>{item.title}</p>
                        <p className='text-wrapper-text'>{item.desc}</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            ) : (
              <div className='ph-two-card-right'>
                <img className='ph-two-child-right-image' src={ item.image } alt='heigouqi'></img>
                <div className='ph-two-child-content'>
                  <Row className="container">
                    <Col span={10} className="image">
                      {/* <div className='image-container'>
                        <img src={item.image} alt="icon"  className='image-content'/>
                      </div> */}
                    </Col>
                    <Col span={14} className="content-right">
                      <div className="text-wrapper">
                        <p className='text-wrapper-title'>{item.title}</p>
                        <p className='text-wrapper-text'>{item.desc}</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            )}
          </div>
        )
      })}
    </div>
  );
};

const ProductHItemFour: React.FC<ItemOne>= ({ data }) => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
  React.useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth <= 576);
      };
  
      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  if(isMobile) {
    return (
      <div className='ph-four-container'>
        <Row gutter={16} justify="space-between" className='ph-four-row'>
        {data.map((item) => (
          <Col span={24} key={item.id} className='ph-four-column'>
            {/* <div className="ph-grid-item">
              <div className="ph-background-image" style={{backgroundImage: `url(${item.image})`}}></div>
              <div className="ph-content">
                <div className="ph-title">{item.title}</div>
              </div>
            </div> */}
            <div className="ph-four-content-wrapper">
              <h3 className="ph-four-title">{item.title}</h3>
              <p className="ph-four-content">{item.desc}</p>
            </div>
          </Col>
        ))}
        </Row>
      </div>
    );
  }

  return (
    <div className='ph-four-container'>
      <Row gutter={16} justify="space-between" className='ph-four-row'>
      {data.map((item) => (
        <Col span={24 / data.length} key={item.id} className='ph-four-column'>
          {/* <div className="ph-grid-item">
            <div className="ph-background-image" style={{backgroundImage: `url(${item.image})`}}></div>
            <div className="ph-content">
              <div className="ph-title">{item.title}</div>
            </div>
          </div> */}
          <div className="ph-four-content-wrapper">
            <h3 className="ph-four-title">{item.title}</h3>
            <p className="ph-four-content">{item.desc}</p>
          </div>
        </Col>
      ))}
      </Row>
    </div>
  );
};

const ProductBItemFive: React.FC<ItemSix>= ({data}) => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
  React.useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth <= 576);
      };
  
      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  if(isMobile) {
    return (
      <div className="ph-six-container">
        <Row gutter={16}>
          <Col span={24}>
            <div className="ph-six-list-container">
              {data.map((item, index) => (
                <div key={index} className='ph-six-list-item'>
                  <div className='ph-six-item-icon'></div>
                  <div className="ph-six-item-title">{item.title}</div>
                  <div className="ph-six-item-description">{item.text}</div>
                  <div className='ph-six-item-line'></div>
                </div>
              ))}
              
            </div>
          </Col>
          <Col span={24}>
            <Card className="ph-six-product-image">
              <img src={ phImage } alt="heigouqi" />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <div className="ph-six-container">
      <Row gutter={16}>
        <Col span={15}>
          <div className="ph-six-list-container">
            {data.map((item, index) => (
              <div key={index} className='ph-six-list-item'>
                <div className='ph-six-item-icon'></div>
                <div className="ph-six-item-title">{item.title}</div>
                <div className="ph-six-item-description">{item.text}</div>
                <div className='ph-six-item-line'></div>
              </div>
            ))}
            
          </div>
        </Col>
        <Col span={9}>
          <Card className="ph-six-product-image">
            <img src={ phImage } alt="heigouqi" />
          </Card>
        </Col>
      </Row>
    </div>
  );
}
