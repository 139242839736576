import React, { Component } from 'react';
import VideoPlayer from 'react-video-js-player';

class HealthVideo extends Component {

  constructor(props) {
    super(props);

    console.log('***************constructor(props):', this.props.videoUrl);

    this.state = {
      video: {
        src: this.props.videoUrl,
      },
      screenWidth: window.innerWidth,
    }
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    
    this.setState({ screenWidth: window.innerWidth });
  };

  player = {}
  // state = {
  //     video: {
  //         src: "http://www.example.com/path/to/video.mp4",
  //         poster: "http://www.example.com/path/to/video_poster.jpg"
  //     }
  // }

  onPlayerReady(player){
      console.log("Player is ready: ", player);
      this.player = player;
  }

  onVideoPlay(duration){
      console.log("Video played at: ", duration);
  }

  onVideoPause(duration){
      console.log("Video paused at: ", duration);
  }

  onVideoTimeUpdate(duration){
      console.log("Time updated: ", duration);
  }

  onVideoSeeking(duration){
      console.log("Video seeking: ", duration);
  }

  onVideoSeeked(from, to){
      console.log(`Video seeked from ${from} to ${to}`);
  }

  onVideoEnd(){
      console.log("Video ended");
  }

  render() {
    const { screenWidth } = this.state;
    let containerWidth = screenWidth;
    if(containerWidth > 720) {
        containerWidth = 720;
    }
    let containerHeight = containerWidth * 420 / 720;

    return (
        <div>
            <VideoPlayer
                controls={true}
                src={this.state.video.src}
                poster={this.state.video.poster}
                width={containerWidth}
                height={containerHeight}
                onReady={this.onPlayerReady.bind(this)}
                onPlay={this.onVideoPlay.bind(this)}
                onPause={this.onVideoPause.bind(this)}
                onTimeUpdate={this.onVideoTimeUpdate.bind(this)}
                onSeeking={this.onVideoSeeking.bind(this)}
                onSeeked={this.onVideoSeeked.bind(this)}
                onEnd={this.onVideoEnd.bind(this)}
            />
        </div>
    );
  }
}
export default HealthVideo;