import React, { useEffect, useState } from 'react';
import DocumentTitle from 'react-document-title';
import Header from '@/components/Header';
import Footer from '@/components/Footer';
import { List } from 'antd';
import HealthVideo from '@/components/HealthVideo';

import '@/styles/health.less';
import {ArticleData, ImageData, TitlesData} from '@/utils/interface';
import Banner from '@/components/Banner';
import banner_7 from '@/assets/banner_7.png';
import h5_banner_7 from '@/assets/H5_banner_7.jpg';
import articleImage1 from '@/assets/article_pic_1.png';
import articleImage2 from '@/assets/article_pic_2.png';
import decoLeft from '@/assets/text_deco_left.png';
import decoRight from '@/assets/text_deco_right.png';

class Health extends React.Component<any, IState> {

  constructor(props) {
    super(props);

    this.state = {
      isMobile: (window.innerWidth <= 576),
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    
    this.setState({ isMobile: (window.innerWidth <= 576) });
  };

  render () {

    const { isMobile } = this.state;
    const bannerDataList: ImageData[] = [{id: 0, image: (isMobile ? h5_banner_7 : banner_7)}];
    const titles: TitlesData = {title: '健康与幸福', sec: '为您和家人的健康保驾护航', color: '#fff'};

    const dataList: ArticleData[] = [
      {id: 0, title: '【揭秘】脂肪肝，近2亿中国人过不了的坎！', sec: '前几天一位老朋友拿着他的检验报告过来让我看看。说这次检查完身体，医生都看了，各方面都挺好的。当我仔细翻看的时候，发现居然已经是中度脂肪肝了。“这怎么算挺好呢？”朋友：医生说，现在脂肪肝的问题多了，没有什么事，不用怕。面对这个说法，我还是有点惊讶的。这叫常见不正常！当我们把自己的命运交到某些人手上的时候，还是需要谨慎一些。所以今天就来和大家聊一聊脂肪肝这个问题。希望对大家有所帮助。', date: '2023/11/18', image: articleImage1, url: 'https://mp.weixin.qq.com/s/DIBUyjX0V5qnmhIVMQ234g'},
      {id: 1, title: '超级抗原开创了肿瘤治疗新时代，让癌症患者重获新生', sec: '由协合集团研发生产的高聚生超级抗原是目前全球成熟的超级抗原抗癌生物制剂，它是由中科院、清华大学、中国军事医学科学院等多家权威性科研机构以及多名科学家的共同努力下，研制出来的，它承载着众人的希望，高聚生超级抗原的研制成功，开创了肿瘤治疗新时代，让癌症患者重获新生。', date: '2023/11/18', image: articleImage2, url: 'https://mp.weixin.qq.com/s/H45LNfoDKYwVx9iGG-vcew'},
    ];

    const didSelectedHandle = (item) => {
      window.open(item.url);
    };

    return (
      <DocumentTitle title={'健康与幸福'}>
      <div className="home-container">
        <Header curActive={'active'} />
        <div style={{background: '#fff', marginTop: '0', width: '100%'}}>
          <Banner dataList={ bannerDataList } titles={titles}/>
          <div>
            <div className='hl-title-container'>
              <img className='hl-image' src={decoLeft} alt="icon" />
              <p className='hl-title'>央视主持人海霞专访</p>
              <img className='hl-image' src={decoRight} alt="icon" />
            </div>
            <div className='health-video-container'>
              <HealthVideo videoUrl={'https://cjkyw.com:8010/introduce.mp4'}/>
            </div>

            <div className='hl-title-container'>
              <img className='hl-image' src={decoLeft} alt="icon" />
              <p className='hl-title'>文章列表</p>
              <img className='hl-image' src={decoRight} alt="icon" />
            </div>
            <div className='he-container'>
              <List
                className='hl-list-custom'
                // itemLayout="horizontal"
                dataSource={dataList}
                renderItem={(item, index) => (
                  <List.Item 
                    style={{borderBottomColor: '#D8D8D8'}} 
                    className="hl-list-item" 
                    key={item.id} 
                    onClick={ () => didSelectedHandle(item)}>
                    <ArticleItem data={ item } key={ item.id }/>
                  </List.Item>
                )}
              />
            </div>
            
          </div>
        </div>
        <Footer />
      </div>
    </DocumentTitle>
    );
  }
}

const ArticleItem: React.FC<ArticleData>= ({ data }) => {

  return (
    <div className="health-list-item">
      <div className="health-item-left">
        <img src={data.image} alt="imageb" className="health-image" /> 
      </div>
      <div className="health-item-right">
        <div className='health-content'>
          {/* <p className='health-item-date'>{data.date}</p> */}
          <p className='health-item-title'>{data.title}</p>
          <p className='health-item-decs'>{data.sec}</p>
        </div>
      </div>
    </div>
  );
};

export default Health;