import ProductList from '../views/ProductList.tsx';
import Home from '../views/Home.tsx';
import NotFound from '../components/404.tsx';
import About from '../views/About.tsx'
import Health from '../views/Health.tsx'
import ProductDetail from '../views/ProductDetail.tsx'

export const routerConfig = [
    {
        path: '/',
        component: Home,
    },
    {
        path: '/ProductList',
        component: ProductList,
    },
    {
        path: '/About',
        component: About,
    },
    {
        path: '/Health',
        component: Health,
    },
    {
        path: '/ProductDetail',
        component: ProductDetail,
    },
    {
        path: '/404',
        component: NotFound,
    }
]