import React from 'react';
import { useState, useEffect } from 'react';
import { Row, Col, Card, Divider , Descriptions, List} from 'antd';

import '@/styles/productdetailruangutai.less';
import decoLeft from '@/assets/text_deco_left.png';
import decoRight from '@/assets/text_deco_right.png';
import itemOne1 from '@/assets/pc_product_ruangutai_pic1-2.png';
import itemOne2 from '@/assets/pc_product_ruangutai_pic2-2.png';
import itemOne3 from '@/assets/pc_product_ruangutai_pic3-2.png';
import itemOne4 from '@/assets/pc_product_ruangutai_pic4-2.png';
import itemRT from '@/assets/pc_product_ruangutai_box2.png';
import itemTwo1 from '@/assets/pc_product_ruangutai_renzhen1.png';
import itemTwo2 from '@/assets/pc_product_ruangutai_renzhen2.png';
import itemTwo3 from '@/assets/pc_product_ruangutai_renzhen3.png';
import itemTwo4 from '@/assets/pc_product_ruangutai_renzhen4.png';
import itemThree1 from '@/assets/pc_product_ruangutai_material_1-2.png';
import itemThree2 from '@/assets/pc_product_ruangutai_material_2-2.png';
import itemThree3 from '@/assets/pc_product_ruangutai_material_3-2.png';
import itemThree4 from '@/assets/pc_product_ruangutai_material_4-2.png';
import itemThree5 from '@/assets/pc_product_ruangutai_material_5-2.png';
import itemFour1 from '@/assets/pc_product_ruangutai_affect_1.png';
import itemFour2 from '@/assets/pc_product_ruangutai_affect_2.png';
import itemFour3 from '@/assets/pc_product_ruangutai_affect_3.png';
import itemFour4 from '@/assets/pc_product_ruangutai_affect_4.png';

interface ItemOne {
  id: number;
  title: string;
  text: string;
  image: string;
};

interface ItemThree {
  id: number;
  title: string;
  desc: string;
  image: string;
};

interface ItemFive {
  id: number;
  title: string;
  text: string;
};

const ProductDetailRuangutai = () => {

  const itemOneData: ItemOne[] = [
    {id: 0, title: '中老年人', text: '骨质流失，容易摔跤骨折，关节劳损，伴随疼痛', image: itemOne1},
    {id: 1, title: '运动爱好者', text: '剧烈运动，加速骨损伤和关节磨损', image: itemOne2},
    {id: 2, title: '上班族/电脑族', text: '久坐不起，坐姿不正确造成腰椎颈椎损伤酸痛难耐', image: itemOne3},
    {id: 3, title: '体力劳动者', text: '长期体力劳动造成关节劳损', image: itemOne4}
  ];

  const itemThreeData: ItemThree[] = [
    {id: 0, title: '软骨肽', desc: '软骨肽中含有两种非常重要的营养物质:胶原蛋白肽和硫酸软骨素。这两种成分相互配合，在身体代谢中发挥调节功能，为我们筑牢了健康防线。', image: itemThree1},
    {id: 1, title: '山梨糖醇', desc: '山梨糖醇是营养性甜味剂、湿润剂、密合剂和稳定剂，广泛应用于生产食品中。', image: itemThree2},
    {id: 2, title: '乳矿物盐', desc: '持续增强骨密度，维持骨骼健康。乳矿物盐能有效地增加人体骨质密度，有助于预防骨质疏松症，而且在补充乳钙几年后骨骼密度仍然持续增长。', image: itemThree3},
    {id: 3, title: '核黄素', desc: '促进机体的生长发育，预防口腔内及皮肤的炎症反应，促进体内铁的吸收。', image: itemThree4},
    {id: 4, title: '牛骨髓肽粉', desc: '预防劳损风湿、跌打损伤和精血亏损。 能调节免疫力和促进骨髓造血功能。含有丰富的蛋白质、钙和脂防，具有补钙作用。适合免疫力低下的患者和老年人，可以提高免疫功能。可与土豆胡萝卜一起食用，食用效果更佳。', image: itemThree5}
  ];

  const itemTwoData: string[] = [itemTwo1, itemTwo2, itemTwo3, itemTwo4];

  const itemFourData: ItemThree[] = [
    {id: 0, title: '调节骨代谢', desc:'软骨肽可以促进钙磷比例吸收的正常，从而起到调节骨代谢的作用。', image: itemFour1},
    {id: 1, title: '促进骨愈合', desc:'软骨肽可以刺激成骨细胞分化，增殖，促进骨骼钙化，从而促进骨愈合。', image: itemFour2},
    {id: 2, title: '缓解疼痛', desc:'软骨肽可以促进骨折造成的水肿的吸收，多肽成分也可缓解骨折引起的疼痛。', image: itemFour3},
    {id: 3, title: '抗炎抗菌', desc:'软骨肽对于关节磨损造成的无菌性炎症有抑制作用，并能提升关节软骨修复能力，降低感染风险，保护关节健康。', image: itemFour4},
  ];

  const itemFiveData: ItemFive[] = [
    {id: 0, title: '产品名称：', text: '超抗源™软骨肤片特殊腾食'},
    {id: 1, title: '产品类型：', text: '运动营养食品耐力类'},
    {id: 2, title: '配  料 表：', text: '软骨肽，山梨糖醇，乳矿物盐，酶解骨粉，牛骨髓肽粉，微晶纤维素，盐酸硫胺素，核黄素，硬脂酸镁'},
    {id: 3, title: '主展示版面标净含量：', text: '30g（0.5g*60）'},
    {id: 4, title: '食用方法：', text: '温水吞食，每日2次，每次两片。'},
    {id: 5, title: '适宜人群：', text: '适用于中长跑、慢跑、快走、自行车、游泳、划船、有氧健身操、舞蹈、户外运动等人群'},
    {id: 6, title: '不适宜人群：', text: '婴幼儿、孕产妇、哺乳期妇女、少年儿童、儿童及过敏体质者不宜食用'},
    {id: 7, title: '保  质  期：', text: '24个月'},
    {id: 8, title: '执行标准：', text: 'GB 24154'},
    {id: 9, title: '食品生产许可证编号：', text: 'SC11337100100862'},
    {id: 10, title: '贮藏方法：', text: '避光，阴凉干燥处保存'},
    {id: 11, title: '温馨提示：', text: '本品不能代替药物'}
  ];

  return (
    <div className='pr-content'>

      <PRTitle title='关节疼痛你还在忍吗'/>
      <ProductRItemOne data={itemOneData}/>

      <PRTitle title='坚持服用 改善关节问题'/>
      <ProductRItemTwo data={itemTwoData}/>

      <PRTitle title='甄选配料 针对补充'/>
      <ProductRItemThree data={itemThreeData}/>

      <PRTitle title='科技助力'/>
      <ProductRItemFour data={itemFourData}/>

      <PRTitle title='产品信息'/>
      <ProductRItemFive data={itemFiveData}/>

    </div>
  );
}

export default ProductDetailRuangutai;

const PRTitle: React.FC<string>= ({ title }) => {

  return (
    <div className='pr-title-container'>
      <img className='pr-image' src={decoLeft} alt="icon" />
      <p className='pr-title'>{title}</p>
      <img className='pr-image' src={decoRight} alt="icon" />
    </div>
  );
};

const ProductRItemOne: React.FC<ItemOne>= ( {data} ) => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
  React.useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth <= 576);
      };
  
      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  if(isMobile) {
    return (
      <div className='pr-one-container'>
        <div>
          <Row gutter={16} className="pr-one-container-b">
          {data.map((item) => (
            <Col span={24} key={item.id}>
              <div className="pr-one-card">
                <div
                  className="pr-one-image"
                  style={{ backgroundImage: `url(${item.image})` }}
                >
                  <div className="pr-one-text">{item.title}</div>
                </div>
                <div className="pr-one-info">{item.text}</div>
              </div>
            </Col>
          ))}
          </Row>
        </div>
      </div>
    );
  }

  return (
    <div className='pr-one-container'>
      <div>
        <Row gutter={16} className="pr-one-container-b">
        {data.map((item) => (
          <Col span={12} key={item.id}>
            <div className="pr-one-card">
              <div
                className="pr-one-image"
                style={{ backgroundImage: `url(${item.image})` }}
              >
                <div className="pr-one-text">{item.title}</div>
              </div>
              <div className="pr-one-info">{item.text}</div>
            </div>
          </Col>
        ))}
        </Row>
      </div>
    </div>
  );
};

const ProductRItemTwo: React.FC<string>= ( {data} ) => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
  React.useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth <= 576);
      };
  
      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  return (
    <div className='pr-two-container'>
      <div className="pr-two-top-container">
        <div className="pr-two-top-row">
          <h1 className="pr-two-top-title">超抗源™软骨肽片特殊膳食</h1>
        </div>
        <div className="pr-two-top-row">
          <p className="pr-two-top-info">多项专业权威认证生产，安心服用，关节轻松！</p>
        </div>
        <div className="pr-two-top-row">
          <img src={itemRT} alt="ruangutai" className="image" />
        </div>
      </div>
      <div>
        <div className="pr-two-bottom-container">
          <Row gutter={16}>
            {data.map((item, index) => (
              isMobile?(
                <Col span={12} key={index}>
                  <img src={item} alt='renzhen' className="pr-two-bottom-image"/>
                </Col>
              ):(
                <Col span={6} key={index}>
                  <img src={item} alt='renzhen' className="pr-two-bottom-image"/>
                </Col>
              )
            ))}
          </Row>
        </div>
      </div>
    </div>
  );
};

const ProductRItemThree: React.FC<ItemThree>= ( {data} ) => {

  return (
    <div className='pr-three-container'>
      {data.map((item, index) => {
        return (
          <div key={index}>
            {index % 2 === 0 ? (
              <div className='pr-three-card-left'>
                <img className='pr-three-child-left-image' src={ item.image } alt='heigouqi'></img>
                <div className='pr-three-child-content'>
                  <Row className="container">
                    <Col span={8} className="image">
                      {/* <div className='image-container'>
                        <img src={item.image} alt="icon"  className='image-content'/>
                      </div> */}
                    </Col>
                    <Col span={16} className="content">
                      <div className="text-wrapper">
                        <p className='text-wrapper-title'>{item.title}</p>
                        <p className='text-wrapper-text'>{item.desc}</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            ) : (
              <div className='pr-three-card-right'>
                <img className='pr-three-child-right-image' src={ item.image } alt='heigouqi'></img>
                <div className='pr-three-child-content'>
                  <Row className="container">
                    <Col span={10} className="image">
                      {/* <div className='image-container'>
                        <img src={item.image} alt="icon"  className='image-content'/>
                      </div> */}
                    </Col>
                    <Col span={14} className="content-right">
                      <div className="text-wrapper">
                        <p className='text-wrapper-title'>{item.title}</p>
                        <p className='text-wrapper-text'>{item.desc}</p>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            )}
          </div>
        )
      })}
    </div>
  );
};

const ProductRItemFour: React.FC<ItemFour>= ( {data} ) => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
  React.useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth <= 576);
      };
  
      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  if(isMobile) {

    return (
      <div className='pr-four-container'>
        <Row gutter={16} className='pr-four-row'>
        {data.map((item) => (
          <Col span={24} key={item.id} style={{background: 'linear-gradient(to right, #FFDE3833, #FFDE3800)', marginBottom: '30px', padding: '0'}}>
            {/* className='pr-four-column' */}
            {/* <div className="ph-grid-item">
              <div className="ph-background-image" style={{backgroundImage: `url(${item.image})`}}></div>
              <div className="ph-content">
                <div className="ph-title">{item.title}</div>
              </div>
            </div> */}
            <div className="pr-four-content-wrapper">
              <img src={item.image} alt='ruangutai'></img>
              <div className='pr-four-content-wrapper-content'>
                <h3 className="pr-four-title">{item.title}</h3>
                <p className="pr-four-content">{item.desc}</p>
              </div>
            </div>
          </Col>
        ))}
        </Row>
      </div>
    );
  }

  return (
    <div className='pr-four-container'>
      <Row gutter={16} justify="space-between" className='pr-four-row'>
      {data.map((item) => (
        <Col span={24 / data.length} key={item.id} className='pr-four-column'>
          {/* <div className="ph-grid-item">
            <div className="ph-background-image" style={{backgroundImage: `url(${item.image})`}}></div>
            <div className="ph-content">
              <div className="ph-title">{item.title}</div>
            </div>
          </div> */}
          <div className="pr-four-content-wrapper">
            <img src={item.image} alt='ruangutai'></img>
            <h3 className="pr-four-title">{item.title}</h3>
            <p className="pr-four-content">{item.desc}</p>
          </div>
        </Col>
      ))}
      </Row>
    </div>
  );
};

const ProductRItemFive: React.FC<ItemFour>= ( {data} ) => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
  React.useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth <= 576);
      };
  
      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  if(isMobile) {

    return (
      <div className="pr-six-container">
        <Row gutter={16}>
          <Col span={24}>
            <div className="pr-six-list-container">
              {data.map((item, index) => (
                <div key={index} className='pr-six-list-item'>
                  <div className='pr-six-item-icon'></div>
                  <div className="pr-six-item-title">{item.title}</div>
                  <div className="pr-six-item-description">{item.text}</div>
                  <div className='pr-six-item-line'></div>
                </div>
              ))}
              
            </div>
          </Col>
          <Col span={24}>
            <Card className="pr-six-product-image">
              <img src={ itemRT } alt="ruangutai" />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <div className="pr-six-container">
      <Row gutter={16}>
        <Col span={15}>
          <div className="pr-six-list-container">
            {data.map((item, index) => (
              <div key={index} className='pr-six-list-item'>
                <div className='pr-six-item-icon'></div>
                <div className="pr-six-item-title">{item.title}</div>
                <div className="pr-six-item-description">{item.text}</div>
                <div className='pr-six-item-line'></div>
              </div>
            ))}
            
          </div>
        </Col>
        <Col span={9}>
          <Card className="pr-six-product-image">
            <img src={ itemRT } alt="ruangutai" />
          </Card>
        </Col>
      </Row>
    </div>
  );
}