/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState, useEffect } from 'react';
// import { NavLink } from 'react-router-dom';
import { Menu, Dropdown, Modal, Form, Button, Input, message, Popover } from 'antd';
// import store from '@/store';
// import { logout } from '@/store/actions';
import logo from '@/assets/tab_bar_logo.png';
import avatar from '@/assets/avatar.jpg';
import '@/styles/header.less';
import { resetPwd } from '@/utils/api';
import { validPass } from '@/utils/valid';
import { Link } from 'react-router-dom';
import './style.less';
import '@/styles/overlayer.less';
import { useHistory } from 'react-router-dom';
import headMenuIcon from '@/assets/head_menu.svg';
import headMenuCloseIcon from '@/assets/head_close.svg';
import headDown from '@/assets/head-down.svg';
import headUp from '@/assets/head-up.svg';

interface Values {
    oldPassword: string,
    newPassword: string,
    confirmPassword: string
}

interface IProps {
    loading: boolean,
    visible: boolean,
    onOk: (values: Values) => void,
    onCancel: () => void
}

const Header = (props: any) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const { curActive } = props;
    console.log('props===', props)

    const history = useHistory();
    const handleMenuClick = (e: any) => {
        console.log('点击了菜单项', e.key);

        //超抗原高聚生口服液 id=0 key=1
        //超抗源八肽饮品 id=1 key=2
        //超抗源黑枸杞地龙蛋白片 id=3 key=3
        //超抗源软骨肽片 id=2 key=4

        let id: string = '';
        switch (e.key) {
            case "1":
                id = '0';
                
                break;
            case "2":
                id = '1';
            
                break;
            case "3":
                id = '3';
            
                break;
            case "4":
                id = '2';
            
                break;
        
            default:
                break;
        }

        console.log('点击了菜单项1', id);

        history.push(`/ProductDetail?id=${id}`);
    };

    const naviMenu = (
        <Menu onClick={handleMenuClick} styleName="custom-dropdown">
            <Menu.Item key="1">高聚生茯苓枸杞口服液</Menu.Item>
            <Menu.Item key="2">超抗源八肽饮品</Menu.Item>
            <Menu.Item key="3">超抗源黑枸杞地龙蛋白片</Menu.Item>
            <Menu.Item key="4">超抗源软骨肽片</Menu.Item>
        </Menu>
    );


    const productMenu = (
        <Menu onClick={handleMenuClick} styleName="custom-dropdown">
            <Menu.Item key="1">高聚生茯苓枸杞口服液</Menu.Item>
            <Menu.Item key="2">超抗源八肽饮品</Menu.Item>
            <Menu.Item key="3">超抗源黑枸杞地龙蛋白片</Menu.Item>
            <Menu.Item key="4">超抗源软骨肽片</Menu.Item>
        </Menu>
    );

    const headMenuDidClick = () => {
        setVisible(!visible);

        if(!visible) {
            document.body.style.overflow = 'hidden';
        }
        else {
            document.body.style.overflow = 'auto';
        }

        window.scrollTo(0, 0);
    };

    const handleCloseModal = (e) => {

        console.log('*****handleCloseModal, e');
        // setVisible(false);
        // document.body.style.overflow = 'auto';
    };

    const headMenuDidClose = () => {

        setVisible(false);
        document.body.style.overflow = 'auto';
    };

    const onContactClick = () => {
        console.log('*************onContactClick');
        window.scrollTo(0, document.documentElement.scrollHeight);
    };

    const handleLogoClick = () => {
        history.push('/');
        headMenuDidClose();
    };

    const toHome = () => {
        history.push('/');
        headMenuDidClose();
    };

    const toAbout = () => {
        history.push('/About');
        headMenuDidClose();
    };

    const toHelth = () => {
        history.push('/Health');
        headMenuDidClose();
    };

    const toContact = () => {
        headMenuDidClose();
        window.scrollTo(0, document.documentElement.scrollHeight);
    };

    const toProduct0 = ()=> {
        history.push(`/ProductDetail?id=0`);
        headMenuDidClose();
    };

    const toProduct1 = ()=> {
        history.push(`/ProductDetail?id=1`);
        headMenuDidClose();
    };

    const toProduct2 = ()=> {
        history.push(`/ProductDetail?id=2`);
        headMenuDidClose();
    };

    const toProduct3 = ()=> {
        history.push(`/ProductDetail?id=3`);
        headMenuDidClose();
    };

    const [isShowPL, setIsShowPL] = useState(false);
    const toProductList = () => {
        setIsShowPL(!isShowPL);
    };

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 576);
        };
    
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className="header-container">
            <div className="header">
                <div className="section">
                    {/* <img src={ logo } alt="logo" /> */}
                    {
                        isMobile ? (
                            <div className='header-content-container'>
                                <img style={{backgroundColor: 'transparent'}} src={ logo } alt="logo" onClick={handleLogoClick} />
                            </div>
                        ) : (

                            <ul>
                                <li>
                                    <Link to='/'>主页</Link>
                                </li>
                                <li>
                                    <Dropdown overlay={productMenu} placement="bottom" overlayClassName="custom-dropdown">
                                        <a href='#'>产品列表</a>
                                    </Dropdown>
                                    {/* <Dropdown overlay={ productMenu } placement="bottomLeft">
                                        <a href='#'>产品列表</a>
                                    </Dropdown> */}
                                    {/* <a href='#' onClick={dropDownAcion}>产品列表</a> */}
                                    {/* <Link  to='/ProductList'>产品列表</Link> */}
                                </li>
                                    <img style={{position: 'center', cursor: 'pointer'}} src={ logo } alt="logo" onClick={handleLogoClick} />
                                <li>
                                    <Link to='/About'>关于我们</Link>
                                </li>
                                <li>
                                    <Link to='/Health'>健康与幸福</Link>
                                </li>
                            </ul>
                        )
                    }
                </div>
                {isMobile ? (
                    <div className='mini-button'>
                        <Button style={{backgroundColor: 'transparent', border: 'none'}} onClick={headMenuDidClick}>
                            <img src={headMenuIcon} alt="icon" style={{display: 'flex', justifyContent: 'center', marginTop: '-10px', width: '32px', height: '32px'}}/>
                        </Button>
                    </div>
                ):(
                    <div className='button'>
                        <Button className='ant-btn-custom-contact' onClick={onContactClick}>联系我们</Button>
                    </div>
                )}
            </div>

            {/* {visible && (
                <div style={{backgroundColor: 'FFDE38', width: '100%', height: '100px'}}>

                </div>
            )} */}
            {visible && (
                <div className="modal" onClick={handleCloseModal}>
                    <div className='modal-top-content'>
                        <Button className='button' onClick={headMenuDidClose}>
                            <img src={headMenuCloseIcon} alt="icon" style={{display: 'flex', justifyContent: 'center', marginTop: '-10px', width: '32px', height: '32px'}}/>
                        </Button>
                    </div>
                    <div className='modal-content'>
                        <ul>
                            <div className='modal-content-menu' onClick={toHome}>
                                主页
                            </div>
                            <div className='modal-content-menu' onClick={toProductList}>
                                <span>产品列表</span>
                                <img src={isShowPL ? headUp : headDown} alt='icon' className='icon'/>
                            </div>
                            {isShowPL && (
                                <div>
                                    <div className='modal-content-menu-product' onClick={toProduct0}>
                                        高聚生茯苓枸杞口服液
                                    </div>
                                    <div className='modal-content-menu-product' onClick={toProduct1}>
                                        超抗源八肽饮品
                                    </div>
                                    <div className='modal-content-menu-product' onClick={toProduct3}>
                                        超抗源黑枸杞地龙蛋白片
                                    </div>
                                    <div className='modal-content-menu-product' onClick={toProduct2}>
                                        超抗源软骨肽片
                                    </div>
                                </div>
                            )}
                            <div className='modal-content-menu' onClick={toAbout}>
                                关于我们
                            </div>
                            <div className='modal-content-menu' onClick={toHelth}>
                                健康与幸福
                            </div>
                            <div className='modal-content-menu' onClick={toContact}>
                                联系我们
                            </div>
                        </ul>
                    </div>
                    
                </div>
            )}


        {/* <Modal
        title="浮层标题"
        visible={visible}
        footer={null}
        style={{ top: 64, bottom: 0 }}
        bodyStyle={{ height: 'calc(100vh - 64px)' }}
        width="100%"
        transitionName="overlay-transition"
        getContainer={() => document.getElementById('header-container')}
      >
        <p>这是浮层的内容</p>
      </Modal> */}
            
        </div>
    )
}

export default Header
