
import * as React from 'react';
import { Row, Col } from 'antd';
import { useState, useEffect } from 'react';
import '@/styles/footer.less';
import logo from '@/assets/footer_logo.png';
import qr from '@/assets/footer_qr.jpg';
import qrPay from '@/assets/pc_pay_QRcode_float.png';
import iconAddr from '@/assets/ic_address.png';
import iconMail from '@/assets/ic_mail.png';
import iconPhone from '@/assets/ic_phone.png';

const contentTitleStyle = {
    marginBottom:'15px', 
    fontSize: '16px', 
    fontWeight: 'bold'
};

const contentInfoStyle = {
    fontSize: '14px',
};

const Footer = () => {

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 576);
        };
    
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    if(isMobile) {
        return (
            <div className="footer-container">
                <div className='top-line'></div>
                {/* <div className="footer">
                    <div className="copyright">
                        Copyright@2020-2025 微信公众号{'<懒人码农>'} 湘ICP备19016532号-1
                    </div>
                </div> */}
                <div className="four-equal-layout">
                    <Row style={{padding: '0', width:'100%'}}>
                        <Col span={12} style={{padding: '0px', margin:'0px'}}>
                            <div class="content-block">
                                <img src={logo} alt='logo'></img>
                            </div>
                            <div class="content-block-left">
                                <p style={ contentTitleStyle }>企业信息</p>
                                <p style={{fontSize: '14px'}}>南京新协合生物科技有限公司</p>
                            </div>
                            <div class="content-block-left-info">
                                <p style={ contentTitleStyle }>联系我们</p>
                                <div style={{display:'flex', alignItems: 'center'}}>
                                    <img style={{marginRight: '10px', width: '12px', height: '12px', marginTop: '-9px'}} src={iconMail} alt='icon'></img>
                                    <p style={contentInfoStyle}>19962057813@qq.com</p>
                                </div>
                                <div style={{display:'flex', alignItems: 'center'}}>
                                    <img style={{marginRight: '10px', width: '12px', height: '12px', marginTop: '-9px'}} src={iconPhone} alt='icon'></img>
                                    <p style={contentInfoStyle}>19962057813</p>
                                </div>
                                <div style={{display:'flex', alignItems: 'center'}}>
                                    <img style={{marginRight: '10px', width: '12px', height: '12px', marginTop: '-9px'}} src={iconAddr} alt='icon'></img>
                                    <p style={contentInfoStyle}>南京市江宁区东吉大道1号</p>
                                </div>
                            </div>
                        </Col>
                        <Col span={12} style={{padding:'0'}}>
                            <div class="content-block-other">
                                <img style={{marginBottom: '10px', width:'121px', height:'165px'}} src={ qrPay } alt='qrpay'></img>
                                <div>
                                    <img style={{marginBottom: '10px'}} src={ qr } alt='qr'></img>
                                    <p style={{marginLeft: '12px', ...contentInfoStyle}}>关注我们 了解更多</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {/* <div class="content-block-left">
                        <p style={ contentTitleStyle }>联系我们</p>
                        <div style={{display:'flex', alignItems: 'center'}}>
                            <img style={{marginRight: '10px', width: '12px', height: '12px', marginTop: '-9px'}} src={iconMail} alt='icon'></img>
                            <p style={contentInfoStyle}>mail@nanjingxinxiehe.com</p>
                        </div>
                        <div style={{display:'flex', alignItems: 'center'}}>
                            <img style={{marginRight: '10px', width: '12px', height: '12px', marginTop: '-9px'}} src={iconPhone} alt='icon'></img>
                            <p style={contentInfoStyle}>+021-123123123</p>
                        </div>
                        <div style={{display:'flex', alignItems: 'center'}}>
                            <img style={{marginRight: '10px', width: '12px', height: '12px', marginTop: '-9px'}} src={iconAddr} alt='icon'></img>
                            <p style={contentInfoStyle}>南京市江宁区东吉大道1号（江宁开发区）</p>
                        </div>
                    </div>
                    <div class="content-block">
                        <img style={{marginBottom: '10px'}} src={ qr } alt='qr'></img>
                        <p style={{marginLeft: '12px', ...contentInfoStyle}}>关注我们 了解更多</p>
                    </div> */}

                        {/* <Row>
                        <Col span={24} style={{ display: 'inline-block' }}>
                            <div className="content">
                                <img src={logo} alt='logo'></img>
                            </div>
                        </Col>
                        </Row>
                        <Row>
                        <Col span={24} style={{ display: 'inline-block' }}>
                            <div className="content">
                                <p style={ contentTitleStyle }>企业信息</p>
                                <p style={{fontSize: '14px'}}>南京新协合生物科技有限公司</p>
                            </div>
                        </Col>
                        </Row> */}
                        
                        
                        {/* <Col span={6}>
                            <div className="content">
                                <p style={ contentTitleStyle }>联系我们</p>
                                <div style={{display:'flex', padding: '0'}}>
                                    <img style={{marginRight: '10px'}} src='' alt=''></img>
                                    <p style={contentInfoStyle}>mail@nanjingxinxiehe.com</p>
                                </div>
                                <div style={{display:'flex'}}>
                                    <img style={{marginRight: '10px'}} src='' alt=''></img>
                                    <p style={contentInfoStyle}>+021-123123123</p>
                                </div>
                                <div style={{display:'flex'}}>
                                    <img style={{marginRight: '10px'}} src='' alt=''></img>
                                    <p style={contentInfoStyle}>南京市江宁区东吉大道1号（江宁开发区）</p>
                                </div>
                            </div>
                        </Col>
                        <Col span={6}>
                            <div className="content">
                                <img style={{marginBottom: '10px'}} src={ qr } alt='qr'></img>
                                <p style={{marginLeft: '12px', ...contentInfoStyle}}>关注我们 了解更多</p>
                            </div>
                        </Col> */}
                        

                </div>
                <div className="copyright">
                    <span style={{color: '#6C6C6C'}}> Copyright © 2023 新协合</span>
                    <span class="link"><a href="https://beian.miit.gov.cn/" style={{color: '#6C6C6C'}}>苏ICP备2023047463号-1</a></span>
                </div>
            </div>
        )
    }
    else {
        return (
            <div className="footer-container">
                <div className='top-line'></div>
                {/* <div className="footer">
                    <div className="copyright">
                        Copyright@2020-2025 微信公众号{'<懒人码农>'} 湘ICP备19016532号-1
                    </div>
                </div> */}
                <div className="four-equal-layout">
                    <Row gutter={16}>
                        <Col xs={24} sm={12} md={6}>
                            <div className="content">
                                <img src={logo} alt='logo'></img>
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={6}>
                            <div className="content">
                                <p style={ contentTitleStyle }>企业信息</p>
                                <p style={{fontSize: '14px'}}>南京新协合生物科技有限公司</p>
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={6}>
                            <div className="content">
                                <p style={ contentTitleStyle }>联系我们</p>
                                <div style={{display:'flex', alignItems: 'center', padding: '0'}}>
                                    <img style={{marginRight: '10px', width: '12px', height: '12px', marginTop: '-11px'}} src={iconMail} alt='icon'></img>
                                    <p style={contentInfoStyle}>19962057813@qq.com</p>
                                </div>
                                <div style={{display:'flex', alignItems: 'center'}}>
                                    <img style={{marginRight: '10px', width: '12px', height: '12px', marginTop: '-11px'}} src={iconPhone} alt='icon'></img>
                                    <p style={contentInfoStyle}>19962057813</p>
                                </div>
                                <div style={{display:'flex', alignItems: 'center'}}>
                                    <img style={{marginRight: '10px', width: '12px', height: '12px', marginTop: '-11px'}} src={iconAddr} alt='icon'></img>
                                    <p style={contentInfoStyle}>南京市江宁区东吉大道1号</p>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={12} md={6}>
                            <div className="other-content">
                                <div style={{height: '100%'}}>
                                    <img style={{marginBottom: '10px'}} src={ qr } alt='qr'></img>
                                    <p style={{marginLeft: '12px', ...contentInfoStyle}}>关注我们 了解更多</p>
                                </div>
                                <img style={{marginLeft: '18px', marginBottom: '10px', width:'121px', height:'165px'}} src={ qrPay } alt='qrpay'></img>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="copyright">
                    <span style={{color: '#6C6C6C'}}> Copyright © 2023 新协合 <a href="https://beian.miit.gov.cn/" style={{color: '#6C6C6C', marginLeft: '20px'}}>苏ICP备2023047463号-1</a></span>
                </div>
            </div>
        )
    }
}

// export default class Footer extends React.Component {

//     render () {

//         // const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
//         // useEffect(() => {
//         //     const handleResize = () => {
//         //         setIsMobile(window.innerWidth <= 576);
//         //     };
        
//         //     window.addEventListener('resize', handleResize);
//         //     return () => {
//         //         window.removeEventListener('resize', handleResize);
//         //     };
//         // }, []);

//         // if(isMobile) {

//         // }
//         // else {
            
//         // }
//         return (
//             <div className="footer-container">
//                 <div className='top-line'></div>
//                 {/* <div className="footer">
//                     <div className="copyright">
//                         Copyright@2020-2025 微信公众号{'<懒人码农>'} 湘ICP备19016532号-1
//                     </div>
//                 </div> */}
//                 <div className="four-equal-layout">
//                     <Row gutter={16}>
//                         <Col xs={24} sm={12} md={6}>
//                             <div className="content">
//                                 <img src={logo} alt='logo'></img>
//                             </div>
//                         </Col>
//                         <Col xs={24} sm={12} md={6}>
//                             <div className="content">
//                                 <p style={ contentTitleStyle }>企业信息</p>
//                                 <p style={{fontSize: '14px'}}>南京新协合生物科技有限公司</p>
//                             </div>
//                         </Col>
//                         <Col xs={24} sm={12} md={6}>
//                             <div className="content">
//                                 <p style={ contentTitleStyle }>联系我们</p>
//                                 <div style={{display:'flex', padding: '0'}}>
//                                     <img style={{marginRight: '10px'}} src='' alt=''></img>
//                                     <p style={contentInfoStyle}>mail@nanjingxinxiehe.com</p>
//                                 </div>
//                                 <div style={{display:'flex'}}>
//                                     <img style={{marginRight: '10px'}} src='' alt=''></img>
//                                     <p style={contentInfoStyle}>+021-123123123</p>
//                                 </div>
//                                 <div style={{display:'flex'}}>
//                                     <img style={{marginRight: '10px'}} src='' alt=''></img>
//                                     <p style={contentInfoStyle}>南京市江宁区东吉大道1号（江宁开发区）</p>
//                                 </div>
//                             </div>
//                         </Col>
//                         <Col xs={24} sm={12} md={6}>
//                             <div className="content">
//                                 <img style={{marginBottom: '10px'}} src={ qr } alt='qr'></img>
//                                 <p style={{marginLeft: '12px', ...contentInfoStyle}}>关注我们 了解更多</p>
//                             </div>
//                         </Col>
//                     </Row>
//                 </div>
//                 <div className="copyright">
//                     © Copyright 2023 by Highfive Design – All right reserved.
//                 </div>
//             </div>
//         )
//     }
// }

export default Footer;