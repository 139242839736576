import React from 'react';
import { useState } from 'react';
import { Row, Col } from 'antd';
import '@/styles/series.less';
import decoLeft from '@/assets/text_deco_left.png'
import decoRight from '@/assets/text_deco_right.png'
import product1 from '@/assets/pc_home_product_1_gaojusheng.png';
import product2 from '@/assets/pc_home_product_2_batai.png';
import product3 from '@/assets/pc_home_product_3_hegouqi.png';
import product4 from '@/assets/pc_home_product_4_ruangutai.png';
import { ProductData } from '@/utils/interface';
import { useHistory } from 'react-router-dom';

const SeriesProduct = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const dataList: ProductData[] = [
    {id: 0, text: '高聚生茯苓枸杞口服液', desc: '10支装x10ml', image: product1},
    {id: 1, text: '超抗源八肽饮品', desc: '10支装x10ml', image: product2},
    {id: 3, text: '超抗源黑枸杞地龙蛋白片', desc: '120g(1.0gx60x2瓶)',  image: product3},
    {id: 2, text: '超抗源软骨肽片', desc: '60g(0.5gx60x2瓶)', image: product4}
  ];

  const history = useHistory();
  const handleItemClick = (item) => {

    history.push(`/ProductDetail?id=${item.id}`);
  };

  return (
    <div className='series-content'>
      <div className='title-container'>
        <img className='image' src={decoLeft} alt="icon" />
        <p className='title'>超抗源系列产品</p>
        <img className='image' src={decoRight} alt="icon" />
      </div>
      <div className="horizontal-layout">
        <Row justify="center">
          {dataList.map((item) => (
            // <SeriesProductItem data={item} key={item.id}/>
            <Col xs={12} sm={12} md={6} lg={6} key={ item.id } onClick={() => handleItemClick(item)}>
              <div className="item">
                <SeriesProductItem data={ item }/>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
};

const SeriesProductItem: React.FC<ProductData>= ({ data }) => {

  return (
    <div className="product-item-content">
      <div className="image-container">
        <img src={ data.image } alt="product" />
      </div>
      <p className="title">{ data.text }</p>
      <p className="subtitle">{ data.desc }</p>
    </div>
  );
};

export default SeriesProduct;