import React, { useEffect, useState } from 'react';
// import { useState } from 'react';
import { Row, Col, Button, Carousel } from 'antd';
import '@/styles/banner.less';
import {ImageData} from '@/utils/interface/index'
// import banner_1 from '@/assets/banner_1.png';

const imageStyle = {
  fontSize: '',
  width: '100%',
  height: '100%',
  padding: '0'
};

const Banner = (props: any) => {
  const {dataList, titles} = props;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePrevPage = () => {
    if(currentPage > 1) {

      setCurrentPage(currentPage - 1);
    }
    else {
      setCurrentPage(1);
    }
  };

  const handleNextPage = () => {
    if(currentPage + 1 <= dataList.length) {

      setCurrentPage(currentPage + 1);
    }
  };

  // const dataList: ImageData[] = [{id: 0, image: banner_1}];

  // {/* Banner 内容 */}
  //           {/* <img style={imageStyle} src={(dataList[currentPage - 1]).image} alt='banner'></img> */}
  //           {/* <h1>Page {currentPage}</h1>
  //           <Button disabled={currentPage === 1} onClick={handlePrevPage}>Prev</Button>
  //           <Button onClick={handleNextPage}>Next</Button> */}

  return (
    <div className="banner">
      <Carousel>
        {dataList?.map((item, index)=>
          (
          // <div key={index} className="image-container" style={{backgroundImage: `url(${(dataList[currentPage - 1]).image}})`}}>
          //   <img className='img' src={(dataList[currentPage - 1]).image} alt="banner"/>
          // </div>
          <div className="banner-n-container" key={index}>
            <div className="banner-n-background-image" style={{backgroundImage: `url(${(dataList[currentPage - 1]).image})`}}></div>
            <div className="banner-n-content">
              <div className='banner-n-text-container'>
                {/* <div className="banner-n-title" style={{ color: titles?.color }}>{titles?.title}</div>
                <div className="banner-n-description" style={{ color: titles?.color }}>{titles?.sec}</div> */}
              </div>
            </div>
          </div>
        ))}
      </Carousel>
      <div style={{backgroundColor: '#FFDE38', width: '100%', height: '6px'}}></div>
      {/* <Row justify="center">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="banner-content">
            <div className="image-container">
              <img src={(dataList[currentPage - 1]).image} alt="banner"/>
            </div>
          </div>
        </Col>
      </Row> */}
      {/* <Row gutter={1}>
          {dataList?.map((item, index)=>(
            <Col key={index} className="gutter-row" span={0} xs={24} sm={24} md={24} lg={24} xl={24}>
              <img src={(dataList[currentPage - 1]).image} alt="banner"/>
            </Col>
          ))}
        </Row> */}
    </div>
  );
};

export default Banner;