import React from 'react';
import { useState, useEffect } from 'react';
import { Row, Col, Card, Divider , Descriptions, List} from 'antd';

import '@/styles/productdetailbatai.less';
import decoLeft from '@/assets/text_deco_left.png';
import decoRight from '@/assets/text_deco_right.png';
import itemOne1 from '@/assets/pc_product_batai_pic_1.png';
import itemOne2 from '@/assets/pc_product_batai_pic_2.png';
import itemOne3 from '@/assets/pc_product_batai_pic_3.png';
import bataiImage from '@/assets/pc_product_batai_product.png';
import itemThree1 from '@/assets/pc_product_batai_material_1.png';
import itemThree2 from '@/assets/pc_product_batai_material_2.png';
import itemThree3 from '@/assets/pc_product_batai_material_3.png';
import itemThree4 from '@/assets/pc_product_batai_material_4.png';
import itemThree5 from '@/assets/pc_product_batai_material_5.png';
import itemThree6 from '@/assets/pc_product_batai_material_6.png';
import itemThree7 from '@/assets/pc_product_batai_material_7.png';
import itemThree8 from '@/assets/pc_product_batai_material_8.png';
import itemFour from '@/assets/pc_product_batai_bottle.png';
import itemFive1 from '@/assets/pc_product_batai_people_1.png';
import itemFive1H5 from '@/assets/pc_product_batai_people_1_h5.png';
import itemFive2 from '@/assets/pc_product_batai_people_2.png';
import itemFive2H5 from '@/assets/pc_product_batai_people_2_h5.png';
import itemFive3 from '@/assets/pc_product_batai_people_3.png';
import itemFive3H5 from '@/assets/pc_product_batai_people_3_h5.png';
import itemFive4 from '@/assets/pc_product_batai_people_4.png';
import itemFive4H5 from '@/assets/pc_product_batai_people_4_h5.png';
import itemFive5 from '@/assets/pc_product_batai_people_5.png';
import itemFive5H5 from '@/assets/pc_product_batai_people_5_h5.png';
import itemFive6 from '@/assets/pc_product_batai_people_6.png';
import itemFive6H5 from '@/assets/pc_product_batai_people_6_h5.png';


interface ItemOne {
  id: number;
  title: string;
  image: string;
};

interface ItemFive {
  id: number;
  title: string;
  text: string;
  image: string;
};

interface ItemSix {
  id: number;
  title: string;
  text: string;
};

const ProductDetailBatai = () => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
  React.useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth <= 576);
      };
  
      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  const itemOneData: ItemOne[] = [
    {id: 0, title: '抑制细胞\n产生性变', desc: '', image: itemOne1},
    {id: 1, title: '促进新陈代谢\n促进创面愈合', desc: '', image: itemOne2},
    {id: 2, title: '延长生命\n有效减缓衰老', desc: '', image: itemOne3},
  ];

  const itemThreeData: ItemOne[] = [
    {id: 0, title: '蜂蛹肽', desc: '', image: itemThree1},
    {id: 1, title: '牡蛎肽', desc: '', image: itemThree2},
    {id: 2, title: '花胶肽', desc: '', image: itemThree3},
    {id: 3, title: '海参肽', desc: '', image: itemThree4},
    {id: 4, title: '阿胶肽', desc: '', image: itemThree5},
    {id: 5, title: '胶原三肽', desc: '', image: itemThree6},
    {id: 6, title: '骨胶原蛋白肽', desc: '', image: itemThree7},
    {id: 7, title: '弹性蛋白肽', desc: '', image: itemThree8}
  ];

  const itemFiveData: ItemFive[] = [
    {id: 0, title: '术后人群', text: '口腔即可直接吸收，帮助人体补充必须的氨基酸、小分子肽和维生素，使术后人群更好的恢复。', image: isMobile ? itemFive1H5 : itemFive1},
    {id: 1, title: '失眠人群', text: '氨基酸是大脑的重要组成部分，补充氨基酸和小分子肽，能促进脑部新陈代谢，恢复大脑功能。', image: isMobile ? itemFive2H5 : itemFive2},
    {id: 2, title: '骨关节疾病人群', text: '补充骨胶原肽，可促进钙吸收和骨胶原分泌，减少晨僵，增强关节灵活度，缓解关节疼痛、肿胀，降低骨质疏松、骨折、风湿、类风湿、腰间盘突出等疾病风险。', image: isMobile ? itemFive3H5 : itemFive3},
    {id: 3, title: '肿瘤人群', text: '增强人体免疫力，能抑制肿瘤细胞变性，增强免疫细胞活性，有效清除对人体有害的自由基；修复人体变性细胞，改善细胞新陈代谢，从一定程度上缓解症状和延长肿瘤患者的寿命。', image: isMobile ? itemFive4H5 : itemFive4},
    {id: 4, title: '心血管疾病人群', text: '加强营养，能够使心血管疾病人群更好的恢复健康，不经胃肠消化就可直接被吸收，帮助人体补充必须的氨基酸、小分子肽和维生素。', image: isMobile ? itemFive5H5 : itemFive5},
    {id: 5, title: '糖尿病人群', text: '通过调整饮食结构，补充多种小分子肽和必须氨基酸，可激活自身免疫系统，调节胰岛β细胞的功能，从而降低血糖水平，改善糖尿病症状，降低糖尿病并发症的发生概率。', image: isMobile ? itemFive6H5 : itemFive6},
  ];

  const itemSixData: ItemSix[] = [
    {id: 0, title: '产品名称：', text: '超抗源™八肽饮品'},
    {id: 1, title: '产品类型：', text: '胶原蛋白肽饮品'},
    {id: 2, title: '配  料 表：', text: '纯化水、大豆低聚糖、骨胶原蛋白肽、水苏糖、胶原三肽、阿胶肽、海参肽、花椒肽、弹性蛋白肽、牡蛎肽、蜂蛹肽、透明质酸钠、柠檬粉、食盐。'},
    {id: 3, title: '产品规格：', text: '10ml/*10'},
    {id: 4, title: '保  质  期：', text: '24个月'},
    {id: 5, title: '执行标准：', text: 'GB 7101'},
    {id: 7, title: '食品生产许可证编号：', text: 'SC11337100100862'},
    {id: 8, title: '不适宜人群：', text: '婴幼儿、孕产妇、哺乳期妇女不宜食用'},
    {id: 9, title: '贮藏方法：', text: '避光，阴凉干燥处保存'},
    {id: 10, title: '温馨提示：', text: '本品不能代替药物'}
  ];

  return (
    <div className='pb-content'>
      {/* <div className='title-container'>
        <img className='image' src={decoLeft} alt="icon" />
        <p className='title'>科技时代 你补肽了吗？</p>
        <img className='image' src={decoRight} alt="icon" />
      </div> */}
      <PBTitle title='科技时代 你补肽了吗？'/>
      <ProductBItemOne data={itemOneData}/>

      <PBTitle title={'肽是生命的基础\n生命是肽的反应体系'}/>
      <ProductBItemTwo/>

      <PBTitle title={'甄选八肽合一 全方位增强'}/>
      <ProductBItemThree data={itemThreeData}/>

      <PBTitle title={'小棕瓶浓缩八肽精华'}/>
      <ProductBItemFour/>

      <PBTitle title={'哪些人群需要补肽'}/>
      <ProductBItemFive data={itemFiveData}/>

      <PBTitle title={'产品信息'}/>
      <ProductBItemSix data={itemSixData}/>

    </div>
  );
}

export default ProductDetailBatai;

const PBTitle: React.FC<string>= ({ title }) => {

  return (
    <div className='pb-title-container'>
      <img className='pb-image' src={decoLeft} alt="icon" />
      <p className='pb-title'>{title}</p>
      <img className='pb-image' src={decoRight} alt="icon" />
    </div>
  );
};

const ProductBItemOne: React.FC<ItemOne>= ({ data }) => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
  React.useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth <= 576);
      };
  
      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  return (
    <div className='pb-grid-item-container'>
      <Row gutter={16}>
      {data.map((item) => (
        isMobile ? (
          <Col span={24} key={item.id}>
            <div className="pb-grid-item">
              <div className="pb-background-image" style={{backgroundImage: `url(${item.image})`}}></div>
              <div className="pb-content">
                <div className="pb-title">{item.title}</div>
                {/* <div className="description">{item.desc}</div> */}
              </div>
            </div>
          </Col>
        ) : (
          <Col span={8} key={item.id}>
            <div className="pb-grid-item">
              <div className="pb-background-image" style={{backgroundImage: `url(${item.image})`}}></div>
              <div className="pb-content">
                <div className="pb-title">{item.title}</div>
                {/* <div className="description">{item.desc}</div> */}
              </div>
            </div>
          </Col>
        )
      ))}
      </Row>
    </div>
  );
}; 

const ProductBItemTwo: React.FC<null>= () => {

  return (
    <div className="two-container">
      <img className='two-child-image' src={ bataiImage } alt='batai'></img>
      <div className='two-child-content'>
        <p className='two-child-item-title'>{`缺乏肽\n会有什么危害`}</p>
        <p className='two-child-item-decs'>•免疫低下 机能退化</p>
        <p className='two-child-item-decs'>•细胞病变 疾病丛生</p>
        <p className='two-child-item-decs'>•加速衰老 健康告急</p>
      </div>
    </div>
  );
}; 

const ProductBItemThree: React.FC<ItemOne>= ({ data }) => {

  return (
    <div className='three-content'>
      <Row gutter={[16, 16]}>
        {data.map((item, index) => (
          <Col span={6} key={index}>
            <div className="three-grid-item">
              <img src={item.image} alt="batai" />
              <div className="text">{item.title}</div>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
}; 

const ProductBItemFour: React.FC<null>= () => {

  return (
    <div className="four-container">
      <img className='four-child-image' src={ itemFour } alt='batai'></img>
      <div className='four-child-content'>
        <p className='four-child-item-title'>{`甄选浓缩\n吸收更快`}</p>
        <p className='four-child-item-decs'>甄选8种优质肽，全新工艺处理</p>
        <p className='four-child-item-decs'>八肽合一浓缩口服液</p>
        <p className='four-child-item-decs'>比起传统粉/片剂，吸收效率更快!</p>
      </div>
    </div>
  );
}; 

const ProductBItemFive: React.FC<ItemFive>= ({ data }) => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
  React.useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth <= 576);
      };
  
      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  return (
    <div className='five-container'>
      <Row gutter={[16, 16]} className="five-container-b">
      {data.map((item) => (
        isMobile ? (
          <Col span={12} key={item.id}>
            <div className="five-card">
              <div
                className="five-image"
                style={{ backgroundImage: `url(${item.image})` }}
              >
                <div className="five-text">{item.title}</div>
              </div>
              <div className="five-info">{item.text}</div>
            </div>
          </Col>
        ):(
          <Col span={8} key={item.id}>
            <div className="five-card">
              <div
                className="five-image"
                style={{ backgroundImage: `url(${item.image})` }}
              >
                <div className="five-text">{item.title}</div>
              </div>
              <div className="five-info">{item.text}</div>
            </div>
          </Col>
        )
      ))}
      </Row>
      <div className="five-extra">适合补充肽的人群，除了以上人群，还可涵盖亚健康人群、过敏人群等特殊人群，肽直接或稍加转化即可被人体细胞吸收，从而为身体细胞提供营养和能量。</div>
    </div>
  );
}; 

const ProductBItemSix: React.FC<ItemSix>= ({data}) => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
  React.useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth <= 576);
      };
  
      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  if(isMobile) {
    return (
      <div className="six-container">
        <Row gutter={16}>
          <Col span={24}>
            <div className="six-list-container">
              {data.map((item, index) => (
                <div key={index} className='six-list-item'>
                  <div className='six-item-icon'></div>
                  <div className="six-item-title">{item.title}</div>
                  <div className="six-item-description">{item.text}</div>
                  <div className='six-item-line'></div>
                </div>
                // <Card key={index} className="six-list-item">
                //   <p>{item.title}</p>
                //   <p>{item.text}</p>
                // </Card>
              ))}
              
            </div>
          </Col>
          <Col span={24}>
            <Card className="six-product-image">
              <img src={ bataiImage } alt="batai" />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }

  return (
    // <div className="six-container">
    //   <div className="six-left">
    //     <List
    //       dataSource={data}
    //       renderItem={(item) => (
    //         <List.Item className="six-list-item">
    //           <div className="six-item-content">
    //             <div className="six-item-title">{item.title}</div>
    //             <div className="six-item-description">{item.text}</div>
    //           </div>
    //         </List.Item>
    //       )}
    //     />
    //   </div>
    //   <div className="six-right">
    //     <img src={bataiImage} alt="Product Image" />
    //   </div>
    // </div>



    <div className="six-container">
      <Row gutter={16}>
        <Col span={15}>
          <div className="six-list-container">
            {data.map((item, index) => (
              <div key={index} className='six-list-item'>
                <div className='six-item-icon'></div>
                <div className="six-item-title">{item.title}</div>
                <div className="six-item-description">{item.text}</div>
                <div className='six-item-line'></div>
              </div>
              // <Card key={index} className="six-list-item">
              //   <p>{item.title}</p>
              //   <p>{item.text}</p>
              // </Card>
            ))}
            
          </div>
        </Col>
        <Col span={9}>
          <Card className="six-product-image">
            <img src={ bataiImage } alt="batai" />
          </Card>
        </Col>
      </Row>
    </div>

    // <div className="six-container">
    //   <Card className="six-card">
    //     <div className="six-content">
    //       {data.map((item, index) => (
    //         <div key={index} className="six-item">
    //           <p className="six-parameter">{item.title}</p>
    //           <p className="six-value">{item.text}</p>
    //         </div>
    //       ))}
    //     </div>
    //     <Divider />
    //     <div className="six-product-image">
    //       <img src={bataiImage} alt="batai" />
    //     </div>
    //   </Card>
    // </div>
  );
}; 