import React from 'react';
import { useState, useEffect } from 'react';
import { List } from 'antd';
import '@/styles/homeproduct.less';
import {ProductData} from '@/utils/interface/index'
import { useHistory } from 'react-router-dom';

import product1 from '@/assets/pc_home_product_1_gaojusheng.png';
import productB1 from '@/assets/pc_home_product_1_photo.png'
import product2 from '@/assets/pc_home_product_2_batai.png';
import productB2 from '@/assets/pc_home_product_2_photo.png'
import product3 from '@/assets/pc_home_product_3_hegouqi.png';
import productB3 from '@/assets/pc_home_product_3_photo.png'
import product4 from '@/assets/pc_home_product_4_ruangutai.png';
import productB4 from '@/assets/pc_home_product_4_photo.png'
import buttonI from '@/assets/btn_product_moreinfo.png'

const buttonStyle = {
  with: '100px',
  height: '46px',
  backgroundImage: `url("${buttonI}")`, // 自定义背景图片路径
  // backgroundSize: 'cover', // 背景图片适应容器大小
  // color: '#ffffff', // 自定义文字颜色
  // border: 'none', // 移除边框
  // borderRadius: '4px', // 添加圆角
  // padding: '8px 16px', // 添加内边距
};

const HomeProduct = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const dataList: ProductData[] = [
    {id: 0, text: '高聚生\n茯苓枸杞口服液', desc: '国家批准，保健食品，增强免疫力，开启美好幸福生活！', image: product1, bimage:productB1},
    {id: 1, text: '超抗源八肽饮品', desc: '利用超抗源生物技术，甑选八肽合一，高效吸收，全面营养，无惧衰老，开启美好幸福生活！', image: product2, bimage:productB2},
    {id: 2, text: '超抗源软骨肽片', desc: '利用超抗源生物技术，调理关节，特殊膳食配方，适用于骨质疏松人群，共享健康生活！',  image: product3, bimage:productB3},
    {id: 3, text: '超抗源\n黑枸杞地龙蛋白片', desc: '利用超抗源生物技术，多种优质蛋白，科学配比，适用于“糖高”人群。', image: product4, bimage:productB4}
  ];

  const history = useHistory();

  const handleClick = (item) => {
    history.push(`/ProductDetail?id=${item.id}`);
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);
  useEffect(() => {
      const handleResize = () => {
          setIsMobile(window.innerWidth <= 576);
      };
  
      window.addEventListener('resize', handleResize);
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  return (
    <List
      itemLayout="horizontal"
      dataSource={dataList}
      renderItem={(item, index) => (
        <List.Item style={{borderBottomColor:'transparent', borderBottomWidth:'0', padding: '0', margin: '0'}} className="list-item" key={item.id}>
          {isMobile ? 
          (<HomeProductItemCenter data={ item } key={ item.id }/>) 
          : 
          (index % 2 === 0 ? <HomeProductItemLeft data={ item } key={ item.id }/> : <HomeProductItemRight data={ item } key={ item.id }/>)
          }
        </List.Item>
      )}
    />
  );
};

const HomeProductItemLeft: React.FC<ProductData>= ({ data }) => {

  const history = useHistory();
  const onClick = () => {
    history.push(`/ProductDetail?id=${data.id}`);
  };

  return (
    <div className="hp-list-item">
      <div className="hp-item-left">
        <img src={data.bimage} alt="imageb" className="hp-image" /> 
      </div>
      <div className="hp-item-right">
        <div className='hp-content'>
          <p className='hp-item-title'>{data.text}</p>
          <p className='hp-item-decs'>{data.desc}</p>
          <button className='hp-custom-button' style={{
            backgroundImage: `url(${buttonI})`
          }} onClick={onClick}></button>
        </div>
      </div>
    </div>
  );
};

const HomeProductItemRight: React.FC<ProductData>= ({ data }) => {

  const history = useHistory();
  const onClick = () => {
    history.push(`/ProductDetail?id=${data.id}`);
  };

  return (
    <div className="hp-list-item">
      <div className="hp-item-right">
        <div className='hp-content'>
          <p className='hp-item-title'>{data.text}</p>
          <p className='hp-item-decs'>{data.desc}</p>
          <button className='hp-custom-button' style={{
            backgroundImage: `url(${buttonI})`
          }} onClick={onClick}></button>
        </div>
      </div>
      <div className="hp-item-left">
        <img src={data.bimage} alt="imageb" className="hp-image" /> 
      </div>
    </div>
  );
};

const HomeProductItemCenter: React.FC<ProductData>= ({ data }) => {

  const history = useHistory();
  const onClick = () => {
    history.push(`/ProductDetail?id=${data.id}`);
  };

  return (
    <div className="hp-list-item">
      <div className="hp-item-left">
        <img src={data.bimage} alt="imageb" className="hp-image" /> 
      </div>
      <div className="hp-item-right">
        <div className='hp-content'>
          <p className='hp-item-title'>{data.text}</p>
          <p className='hp-item-decs'>{data.desc}</p>
          <button className='hp-custom-button' style={{
            backgroundImage: `url(${buttonI})`
          }} onClick={onClick}></button>
        </div>
      </div>
    </div>
  );
}

export default HomeProduct;